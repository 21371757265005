import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery, Typography, Backdrop, CircularProgress,} from "@mui/material";

// project import
import Drawer from "./drawer/Drawer";
import Header from "./header/Header";

// types
import { openDrawer, setPasswordReset } from "../../store/slices/applicationSlice";

import { bloqcibeApi, useGetSponsorDetailsQuery, useGetMeUserQuery, useGetUserContactsQuery } from "../../store/slices/apiSlice";
import ShowMessage from "../../components/showMessage/ShowMessage";
import { WebSocketProvider } from "../../hooks/WebsocketProvider";
import { setSubjectLoggedIn } from "../../store/slices/authSlice";
import ResetPasswordDialog from "../../components/common/resetPasswordDialog";
import ProfileSetup from "../../pages/profileSetup/ProfileSetup"
import AnimatedBloqcubeLogo from "../../components/Logo/AnimatedBloqcubeLogo";
import { getUserRole } from "../../pages/util";
import ExpiredDialog from "../../components/common/ExpiredSessionDialog";
import moment from "moment";
import Chats from "../../components/common/chats/Chats";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const user = useSelector((state) => state.auth.user);
  const { data } = useGetSponsorDetailsQuery(user?.details?.[0]?.[0]?.sponsorId);
  const drawerOpen = useSelector((state) => state.application.drawerOpen);

  const userRole = getUserRole(user);

  const [openDialog, setOpenDialog] = React.useState(false);

  const sessionExpiredDialog = useSelector((state) => state.application.sessionExpiredDialog);

  

  const {data:userData} = useGetMeUserQuery()
  const {data:userContacts} = useGetUserContactsQuery()
  const [getSubjectDashboardDetail] =
    bloqcibeApi.endpoints.getSubjectDashboardDetail.useLazyQuery();

  // backdrop toggler
  const backdropLoading = useSelector(state => state.application.backdropLoading)

  // drawer toggler

  const handlePasswordDialogClose = ()=>{
    setOpenDialog(false);
  }

  const [open, setOpen] = useState(drawerOpen);

  const [isExpired, setIsExpired] = useState(false);
  const [message, setMessage] = useState('');


  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(()=>{
    if(sessionExpiredDialog){
      setIsExpired(true);
      setMessage("Your session is expired. Please Login again.")
    }
    if(userRole===''){
      setIsExpired(true);
      setMessage("You have no assigned trials. Please contact the sponsor admin.")
    }
  },[sessionExpiredDialog,userRole])

  useEffect(() => {
    if (user && user.details?.[0]?.[0]?.['role'] == 'subject') {
      getSubjectDashboardDetail(user.details?.[0]?.[0]?.['userId']).then(response => {
        if(response?.data?.hcDetail?.status === 'Withdrawal' || response?.data?.icDetail?.status === 'Withdrawal'){
          setIsExpired(true); 
          setMessage(`You have been withdrawn from the trial - ${response?.data?.trialDetail.trialTitle}.`)
        }
        if(response?.data?.hcDetail?.status === 'Enrollment_Failure' || response?.data?.icDetail?.status === 'Enrollment_Failure'){
          setIsExpired(true); 
          setMessage(`You have been removed from the trail - ${response?.data?.trialDetail.trialTitle} due to a screening failure.`)
        }
      });
      dispatch(setSubjectLoggedIn(true));
    }
  }, [user]);
  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    const sevenDaysBeforeExpiry = moment(userData?.passwordExpiry).subtract(7, 'days');
    const now = moment();

    if (!userData) {
      return;
    }
if (userData?.tempPassword && (!userData?.isEmailVerified || !userData?.isPhoneVerified)) {
  setOpenDialog(true);
} else if (
  userData?.tempPassword &&
  now.isBefore(userData?.passwordExpiry) &&
  userData?.isEmailVerified &&
  userData?.isPhoneVerified
) {
  // Case 1: Show non-cancellable dialog for temp password reset
  dispatch(
    setPasswordReset({
      openDialog: true,
      formLabel: 'You are using a temporary password. Please reset your password to continue operations.',
      isCancellable: true,
      isTempPassword: true,
    })
  );
} else if (userData?.tempPassword && now.isAfter(userData?.passwordExpiry)) {
  // Case 2: Temporary password is expired
  dispatch(
    setPasswordReset({
      openDialog: true,
      formLabel: 'Your temporary password has expired. Please reset your password to continue operations.',
      isCancellable: false,
      isTempPassword: true,
    })
  );
} else if (now.isAfter(userData?.passwordExpiry)) {
  // Case 3: Regular password is already expired
  dispatch(
    setPasswordReset({
      openDialog: true,
      formLabel: `Your password has expired. Please reset your password to continue operations.`,
      isCancellable: false,
    })
  );
} else if (now.isSameOrAfter(sevenDaysBeforeExpiry) && now.isBefore(userData?.passwordExpiry)) {
  const daysRemaining = moment(userData?.passwordExpiry).diff(now, 'days');

  let formLabel = '';
  if (daysRemaining > 1) {
    // Case 4: 2-7 days remaining
    formLabel = `Your password will expire in ${daysRemaining} days. Please reset it.`;
  } else if (daysRemaining === 1) {
    // Case 5: 1 day remaining
    formLabel = `Your password will expire in 1 day. Please reset it soon.`;
  } else {
    // Case 6: 0 days remaining, expiring today
    const expiryTime = moment(userData?.passwordExpiry).format('hh:mm A');
    formLabel = `Your password will expire today at ${expiryTime}. Please reset it as soon as possible.`;
  }

  dispatch(
    setPasswordReset({
      openDialog: true,
      formLabel: formLabel,
      isCancellable: true,
    })
  );
} else {
  // No need to show the dialog
  dispatch(
    setPasswordReset({
      openDialog: false,
    })
  );
}

  },[userData, dispatch])

  return (
    <WebSocketProvider>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          '@media (max-width:600px)': {
            backgroundColor: '#F1F2F5',
          },
        }}
      >
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{
            width: '100%',
            minHeight: '100vh',
            backgroundColor: '#FAFAFA',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
          }}
        >
          <Box>
            <Toolbar />
            <Outlet />
          </Box>
          <Box sx={{ marginTop: 'auto' }}>
            <Box height={10} sx={{ display: 'flex', justifyContent: 'flex-end', py: 2 }}>
              <Typography variant="caption" color={theme.palette.grey[500]}>
                Copyright &copy; Bloqcube 2023. All rights reserved.
              </Typography>
            </Box>
          </Box>
          <ShowMessage />
        </Box>
      </Box>
      {(userRole !== 'sponsor' || userRole !== 'system_admin') && <Chats />}
      <ExpiredDialog isOpen={isExpired} message={message} />

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropLoading}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ height: 150, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <AnimatedBloqcubeLogo />
          </Box>
          <Typography variant="subtitle2" color="inherit">
            Please wait, We are loading your data...
          </Typography>
        </Box>
      </Backdrop>

      {!isExpired && <ResetPasswordDialog />}

      {!isExpired && openDialog && (
        <ProfileSetup
          userData={userData}
          openDialog={openDialog}
          handlePasswordDialogClose={handlePasswordDialogClose}
        />
      )}
    </WebSocketProvider>
  );
};

export default MainLayout;
