import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Container, Drawer, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Flex, VideoTileGrid, useMeetingManager, useRosterState } from 'amazon-chime-sdk-component-library-react';
import {
  ConsoleLogger,
  DefaultEventController,
  LogLevel,
  MeetingSessionConfiguration,
  NoOpEventReporter,
} from 'amazon-chime-sdk-js';
import React, { useEffect, useState } from 'react';
import { useResponsive } from '../../hooks/ResponsiveProvider';
import { bloqcibeApi } from '../../store/slices/apiSlice';
import AttendeeList from './AttendeeList/AttendeeList';
import MeetingControls from './controls/MeetingControls';
import DeviceSelection from './deviceSelection/DeviceSelection';
import CustomVideoTile from './customVideoTile/CustomVideoTile';
import { useParams } from 'react-router-dom';

const JoinMeetingButton = ({ setDeviceSelection }) => {
  const meetingManager = useMeetingManager();
  const [meetingId, setMeetingId] = useState('');
  const [getMeetingDetails] = bloqcibeApi.endpoints.getMeetingDetails.useLazyQuery();

  // Function to join a meeting
  const joinMeeting = async () => {
    try {
      const { data: meetingResult } = await getMeetingDetails(meetingId);

      const mediaPlacements = JSON.parse(meetingResult.meeting.mediaPlacements);

      const meeting = {
        Meeting: {
          MeetingId: meetingResult.meeting.meetingId,
          MediaPlacement: mediaPlacements,
        },
      };

      const attendee = {
        Attendee: {
          ExternalUserId: meetingResult.yourAttendeeDetails.userId,
          AttendeeId: meetingResult.yourAttendeeDetails.attendeeId,
          JoinToken: meetingResult.yourAttendeeDetails.joiningToken,
        },
      };

      // Request permission for video input
      const videoInputDevice = await meetingManager.selectVideoInputDevice('default');

      // If the user grants permission, enable the video tile
      if (videoInputDevice) {
        await meetingManager.startVideoInput(videoInputDevice);
      }

      const meetingSessionConfiguration = new MeetingSessionConfiguration(meeting, attendee);
      const eventController = new DefaultEventController(
        meetingSessionConfiguration,
        new ConsoleLogger('SDK', LogLevel.WARN),
        new NoOpEventReporter()
      );
      const options = {
        eventController,
      };

      try {
        await meetingManager.join(meetingSessionConfiguration, options);
      } catch {
        console.error('Something went wrong');
      }

      setDeviceSelection(true);
    } catch (error) {
      console.error('Error joining the meeting', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" textAlign="center" marginBottom="20px">
        Join a Meeting
      </Typography>

      <Box component="form" noValidate autoComplete="off" marginBottom="20px">
        <TextField
          label="Meeting ID"
          variant="outlined"
          fullWidth
          value={meetingId}
          onChange={(e) => setMeetingId(e.target.value)}
          margin="normal"
          required
        />
        <Button variant="contained" color="primary" fullWidth onClick={joinMeeting}>
          Join Meeting
        </Button>
      </Box>
    </Container>
  );
};

const JoinMeeting = () => {
  const [deviceSelection, setDeviceSelection] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isSmallScreen } = useResponsive();
  const { meetingId } = useParams()
  const [getMeetingDetails] = bloqcibeApi.endpoints.getMeetingDetails.useLazyQuery();
  const meetingManager = useMeetingManager();
  
  useEffect(() => {
    if (!meetingId) return
    const joinMeeting = async () => {
      try {
        const { data: meetingResult } = await getMeetingDetails(meetingId);

        const mediaPlacements = JSON.parse(meetingResult.meeting.mediaPlacements);

        const meeting = {
          Meeting: {
            MeetingId: meetingResult.meeting.meetingId,
            MediaPlacement: mediaPlacements,
          },
        };

        const attendee = {
          Attendee: {
            ExternalUserId: meetingResult.yourAttendeeDetails.userId,
            AttendeeId: meetingResult.yourAttendeeDetails.attendeeId,
            JoinToken: meetingResult.yourAttendeeDetails.joiningToken,
          },
        };

        // Request permission for video input
        const videoInputDevice = await meetingManager.selectVideoInputDevice('default');

        // If the user grants permission, enable the video tile
        if (videoInputDevice) {
          await meetingManager.startVideoInput(videoInputDevice);
        }

        const meetingSessionConfiguration = new MeetingSessionConfiguration(meeting, attendee);
        const eventController = new DefaultEventController(
          meetingSessionConfiguration,
          new ConsoleLogger('SDK', LogLevel.WARN),
          new NoOpEventReporter()
        );
        const options = {
          eventController,
        };

        try {
          await meetingManager.join(meetingSessionConfiguration, options);
        } catch {
          console.error('Something went wrong');
        }

        setDeviceSelection(true);
      } catch (error) {
        console.error('Error joining the meeting', error);
      }
    };

    joinMeeting()

    return () => {
      meetingManager.leave();
    }

  }, [meetingId]);

  return (
    <>
      {!meetingId ? (
        <JoinMeetingButton setDeviceSelection={setDeviceSelection} />
      ) : meetingId && deviceSelection === true ? (
        <DeviceSelection setDeviceSelection={setDeviceSelection} />
      ) : (
        <Grid container sx={{ height: '85vh', width: '100%' }} spacing={!isSmallScreen && 2}>
          <Grid item md={9} sm={12} xs={12} sx={{ position: 'relative' }}>
            <VideoTileGrid layout={!isSmallScreen ? 'featured' : 'standard'} noRemoteVideoView={<MeetingDetails />} />
            <Box sx={{ position: 'absolute', bottom: '1rem', left: '50%', transform: 'translateX(-50%)' }}>
              <MeetingControls setAttendeesDrawerOpen={() => setDrawerOpen(true)} />
            </Box>
          </Grid>
          {!isSmallScreen && (
            <Grid item md={3} sm={0}>
              <AttendeeList />
            </Grid>
          )}
          {isSmallScreen && (
            <>
              <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <Box sx={{ width: 250, padding: 2 }}>
                  <AttendeeList />
                </Box>
              </Drawer>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

const MeetingDetails = () => {
  // const { meetingId, toggleTheme, theme } = useAppState();
  const manager = useMeetingManager();
  return (
    <Flex container layout="fill-space-centered">
      <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem">
        <div>
          <dt>Waiting for participants to join</dt>
          {/* <dd>{meetingId}</dd> */}
        </div>
      </Flex>
    </Flex>
  );
};

export default JoinMeeting;
