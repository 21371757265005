// types
import { createSlice } from '@reduxjs/toolkit';
import { bloqcibeApi } from './apiSlice';

// initial state
const initialState = {
  openItem: ['sponsor'],
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: false,
  componentDrawerOpen: true,
  backdropLoading: false,
  sessionExpiredDialog: false,
  quizDrawer: false,
  passwordReset: {
    openDialog: false,
    formLabel: false,
    isCancellable: true,
    isTempPassword: false
  },
  pagination :{
    SUBJECT_TABLE:{
      page:0,
      rowsPerPage:5
    },
    WITHDRAW_SUBJECT_TABLE:{
      page:0,
      rowsPerPage:5
    },
    TRAIL_DETAILS_TABLE:{
      page:0,
      rowsPerPage:5
    },
    BOOKED_VISIT_SLOTS:{
      page:0,
      rowsPerPage:5
    },
    DRAFT_TRIALS_TABLE:{
      page:0,
      rowsPerPage:5
    },
    ONGOING_TRIALS_TABLE:{
      page:0,
      rowsPerPage:5
    },
    SUBJECT_AE_LIST:{
      page:0,
      rowsPerPage:5
    },
    DOA_LOG_TABLE:{
      page:0,
      rowsPerPage:5
    },
    DOA_DELIGATION_CONFIGURATION:{
      page:0,
      rowsPerPage:5
    },
    DOA_SELECTED_USER_LOG:{
      page:0,
      rowsPerPage:5
    },
    DOA_MY_TASK:{
      page:0,
      rowsPerPage:5
    },
    DOA_COMPLETED_TASK:{
      page:0,
      rowsPerPage:5
    },
  },
};

// ==============================|| SLICE - MENU ||============================== //

const application = createSlice({
  name: 'application',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    updatePagination(state, action) {
      const tableType = action.payload?.tableType;
      const copyPagination = { ...state.pagination };

      copyPagination[tableType].page = action.payload?.page;
      copyPagination[tableType].rowsPerPage = action.payload?.rowsPerPage;

      state.pagination = copyPagination;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    openBackdropLoading(state, action) {
      state.backdropLoading = action.payload.backdropLoading;
    },

    openSessionExpiredDialog(state, action) {
      state.sessionExpiredDialog = action.payload;
    },

    setQuizDrawer(state, action) {
      state.quizDrawer = action.payload;
    },

    setPasswordReset(state, action) {
      const { openDialog, formLabel, isCancellable, isTempPassword } = action.payload;
      if (openDialog !== undefined) {
        state.passwordReset.openDialog = openDialog;
      }
      if (formLabel !== undefined) {
        state.passwordReset.formLabel = formLabel;
      }
      if (isCancellable !== undefined) {
        state.passwordReset.isCancellable = isCancellable;
      }
      if (isTempPassword !== undefined) {
        state.passwordReset.isTempPassword = isTempPassword;
      }
    },
  },
  extraReducers: (builder) => {
    // Dynamic form loading
    builder.addMatcher(bloqcibeApi.endpoints.getLibraryForm.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.getLibraryForm.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.getLibraryForm.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    // Scheduler events loading
    builder.addMatcher(bloqcibeApi.endpoints.getSchedule.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.getSchedule.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.getSchedule.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    // External Verify CRF forms loading
    builder.addMatcher(bloqcibeApi.endpoints.externalVerifyCRF.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.externalVerifyCRF.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.externalVerifyCRF.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    // External Verify Subject Enrolment forms loading
    builder.addMatcher(bloqcibeApi.endpoints.externalVerifySubject.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.externalVerifySubject.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.externalVerifySubject.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    // Update trial / Create trial loading
    builder.addMatcher(bloqcibeApi.endpoints.createTrial.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.createTrial.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.createTrial.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    builder.addMatcher(bloqcibeApi.endpoints.createTrialPatch.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.createTrialPatch.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.createTrialPatch.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    builder.addMatcher(bloqcibeApi.endpoints.updateTrialPatch.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.updateTrialPatch.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.updateTrialPatch.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    builder.addMatcher(bloqcibeApi.endpoints.createTrialSitePatch.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.createTrialSitePatch.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.createTrialSitePatch.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    builder.addMatcher(bloqcibeApi.endpoints.markTrialSetupDone.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.markTrialSetupDone.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.markTrialSetupDone.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });

    builder.addMatcher(bloqcibeApi.endpoints.getTrials.matchPending, (state, action) => {
      state.backdropLoading = true;
    });
    builder.addMatcher(bloqcibeApi.endpoints.getTrials.matchFulfilled, (state, action) => {
      state.backdropLoading = false;
    });
    builder.addMatcher(bloqcibeApi.endpoints.getTrials.matchRejected, (state, action) => {
      state.backdropLoading = false;
    });
  },
});

export default application.reducer;

export const {
  activeItem,
  activeComponent,
  updatePagination,
  openDrawer,
  openComponentDrawer,
  openBackdropLoading,
  openSessionExpiredDialog,
  setQuizDrawer,
  setPasswordReset,
} = application.actions;
