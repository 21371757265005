import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onRealtimeMessage, openChat, setSelectedUser, updateChatThread } from '../../../store/slices/chatSlice';
import { useResponsive } from '../../../hooks/ResponsiveProvider';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CustomButton from '../../@extended/CustomButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import StyledList from '../../@extended/StyledList';
import AddIcon from '@mui/icons-material/Add';
import {
  bloqcibeApi,
  useGetMessageThreadsQuery,
  useReadMessageMutation,
  useSendMessageMutation,
} from '../../../store/slices/apiSlice';

import video from '../../../assets/images/Icons/video.png';
import { base64ToArrayBuffer } from '../DocumentUpload';
import moment from 'moment';
import WebsiteLogo from './WebsiteLogo';
import { useNavigate } from 'react-router-dom';
import ChatMessageBox from './ChatMessageField';

const ChatMessageDrawer = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isSmallScreen } = useResponsive();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const [sendMessage, { isLoading }] = useSendMessageMutation();
  const [readMessage, { isLoadingReadMessage }] = useReadMessageMutation();
  const { data } = useGetMessageThreadsQuery();
  const [downloadUserProfilePic] = bloqcibeApi.endpoints.downloadUserProfilePic.useLazyQuery();
  const [getSingleChatThread] = bloqcibeApi.endpoints.getSingleChatThread.useLazyQuery();
  const loggedInUser = useSelector((state) => state.userDetails?.loggedInUser);
  const isSubjectLoggedIn = useSelector((state) => state.auth?.subjectLoggedIn);
  const piUsers = useSelector((state) => state.subject?.dashboardDetails?.piUsers);
  const open = useSelector((state) => state.chats.openChat);
  const chatThreads = useSelector((state) => state.chats.chatThreads);
  const contacts = useSelector((state) => state.chats.contacts);
  const selectedUser = useSelector((state) => state.chats.selectedUser);
  const lastMessageRef = useRef(null);
  const [profilePics, setProfilePics] = useState({});
  const [openContacts, setOpenContacts] = useState(false);
  let unreadMessages = [];
  let dateSection;

  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (openPopover ? 'simple-popover' : undefined), [anchorEl, openPopover]);

  const [chat, setChat] = useState('');
  if (isSubjectLoggedIn) {
    // dispatch(setSelectedUser({ id: '' }));
  }

  useEffect(() => {
    if (selectedUser && selectedUser?.messages?.length !== 0 && lastMessageRef.current) {
      setTimeout(() => {
        lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
    setChat('');
    return () => {
      readMessageStatus();
    };
  }, [selectedUser]);

  useEffect(() => {
    if (piUsers && chatThreads.length == 0 && isSubjectLoggedIn) {
      dispatch(setSelectedUser({ userDetails: piUsers[0], messages: [] }));
    }
    if (chatThreads.length !== 0 && isSubjectLoggedIn) {
      dispatch(setSelectedUser(chatThreads[0]));
    }
  }, [piUsers, chatThreads, isSubjectLoggedIn]);

  useEffect(() => {
    const fetchProfilePics = async () => {
      const newProfilePics = {};

      await Promise.all(
        chatThreads.map(async (user) => {
          if (user.userDetails?.profileS3Key) {
            try {
              const result = await downloadUserProfilePic({
                s3Key: user.userDetails?.profileS3Key,
              });
              if (result.data) {
                const byteArray = base64ToArrayBuffer(result.data);
                const rr = new Blob([byteArray], { type: 'application/octet-stream' });
                const reader = new FileReader();
                reader.readAsDataURL(rr);
                reader.onloadend = function () {
                  const base64data = reader.result;
                  newProfilePics[user.userDetails.profileS3Key] = base64data;
                };
              }
            } catch (error) {
              console.error('Error fetching profile picture for user:', user.userDetails.firstName, error);
              newProfilePics[user.userDetails.profileS3Key] = '';
            }
          }
        })
      );

      setProfilePics(newProfilePics);
    };

    fetchProfilePics();
  }, [chatThreads]);

  const handleCloseChat = () => {
    if (!isSubjectLoggedIn) {
      dispatch(setSelectedUser(null));
    }
    dispatch(openChat(false));
    if (unreadMessages.length != 0) {
      readMessageStatus();
    }
  };

  const sendChat = async (type) => {
    try {
      switch (type) {
        case 'MEETING_REQUESTED':
          const result = await sendMessage({
            sentBy: loggedInUser?.id,
            sentTo: selectedUser?.userDetails?.id || selectedUser?.userDetails?.userId,
            content: `The ${isSubjectLoggedIn ? 'subject' : 'user'}  ${loggedInUser?.firstName + ' ' + loggedInUser?.lastName} has requested a meeting.`,
            contentType: 'MEETING_REQUESTED',
          }).unwrap();
          if(selectedUser.messages.length === 0){
            const tags = isSubjectLoggedIn ? ["allMessages"] : ["UserContacts", "allMessages"]
            dispatch(bloqcibeApi.util.invalidateTags(tags))
          }
          dispatch(updateChatThread({ selectedUserId: selectedUser?.userDetails?.id, message: result }));
          break;
        case 'MEETING_CREATE':
          const result1 = await sendMessage({
            sentBy: loggedInUser?.id,
            sentTo: selectedUser?.userDetails?.id || selectedUser?.userDetails?.userId,
            content: ` ${loggedInUser?.firstName + ' ' + loggedInUser?.lastName} has created a meeting.`,
            contentType: 'MEETING_CREATE',
          });
          if(selectedUser.messages.length === 0){
            dispatch(bloqcibeApi.util.invalidateTags(["UserContacts","allMessages"]))
          }
          const allMessages = await getSingleChatThread(selectedUser?.userDetails?.id);
          if (result1.data) {
            dispatch(onRealtimeMessage({ sentBy: selectedUser?.userDetails?.id, thread: allMessages?.data }));
          }
          break;
        default:
          if (!chat.trim()) return;
          const _result = await sendMessage({
            sentBy: loggedInUser?.id,
            sentTo: selectedUser?.userDetails?.id || selectedUser?.userDetails?.userId,
            content: chat,
            contentType: 'TEXT',
          }).unwrap();
          if(selectedUser.messages.length === 0){
           dispatch(bloqcibeApi.util.invalidateTags(["UserContacts","allMessages"]))
          }
          dispatch(updateChatThread({ selectedUserId: selectedUser?.userDetails?.id, message: _result }));
          setChat('');
          break;
      }
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const readMessageStatus = async () => {
    try {
      if (unreadMessages.length != 0) await readMessage(unreadMessages).unwrap();
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const onClickVideo = async () => {
    handleClose();
    if (isSubjectLoggedIn) {
      sendChat('MEETING_REQUESTED');
    } else {
      sendChat('MEETING_CREATE');
      // window.open('https://zoom.us/', '_blank');
    }
  };

  const handleMeetingJoin = (payload) => {
    if (!payload) return;
    const _payload = JSON.parse(payload);
    handleCloseChat();
    navigate(`/meeting/${_payload?.meetingId}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function renderContent(content) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = content.split(urlRegex);

    const links = [];

    // Render content with links
    const renderedContent = parts.map((part, index) => {
      if (urlRegex.test(part)) {
        links.push(part);
        return (
          <Link key={`link-${index}`} href={part} underline="hover" target="_blank" rel="noopener noreferrer">
            {part}
          </Link>
        );
      }
      return part;
    });

    return (
      <Box>
        {links.length === 1 && <WebsiteLogo url={links[0]} />}
        <Typography sx={{ wordBreak: 'break-word' }}>{renderedContent}</Typography>
      </Box>
    );
  }

  const handleSelectUser = (contact) => {
    const payload = {
      userDetails: {
        id: contact.user.id,
        email: contact.user.email,
        profileS3Key: contact.user.profileS3Key,
        firstName: contact.user.firstName,
        lastName: contact.user.lastName,
        contactNumber: contact.user.contactNumber,
        active: contact.user.active,
      },
      messages: [],
    };
    dispatch(setSelectedUser(payload));
    setOpenContacts(false);
  };

  const handleBackClick = () => {
    dispatch(setSelectedUser(null));
    readMessageStatus();
  };

  const titleContent = useMemo(() => {
    if (selectedUser !== null) {
      if (!isSubjectLoggedIn) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <UserInfo selectedUser={selectedUser} onBackClick={handleBackClick} profilePics={profilePics} />
            <IconButton sx={{ padding: 0 }} onClick={handleClick} aria-describedby={id}>
              <img src={video} alt="file" style={{ height: 40 }} />
            </IconButton>
            <MeetingPopover
              id={id}
              openPopover={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              onClick={onClickVideo}
              isSubject={true}
            />
          </Box>
        );
      } else {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <UserInfo
              selectedUser={selectedUser}
              onBackClick={() => {
                dispatch(openChat(false));
                readMessageStatus();
              }}
            />
            <IconButton sx={{ padding: 0 }} onClick={handleClick} aria-describedby={id}>
              <img src={video} alt="file" style={{ height: 40 }} />
            </IconButton>
            <MeetingPopover
              id={id}
              openPopover={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              onClick={onClickVideo}
              isSubject={false}
            />
          </Box>
        );
      }
    } else if (openContacts) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton disableRipple onClick={() => setOpenContacts(false)} sx={{ height: 10 }}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" color="text.primary">
              Contacts
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" color="initial">
          Messages
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="text"
            size="small"
            onClick={(event) => {
              setOpenContacts(true); // This sets openContacts state
            }}
            startIcon={<AddIcon />}
          >
            New Chat
          </Button>
        </Box>
      </Box>
    );
  }, [isSubjectLoggedIn, selectedUser, openContacts, anchorEl, openPopover, id]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleCloseChat}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isSmallScreen ? '100%' : '500px', // Set the width of the Drawer paper
            height: '100%',
            paddingTop: { sm: 0, md: 0, lg: 8 },
            backgroundColor: '#F5F5F5',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {/* Card Section */}
          <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            {/* Header Section inside the Card */}
            <CardHeader
              title={titleContent}
              titleTypographyProps={{ variant: 'subtitle1', fontWeight: 600 }}
              sx={{ backgroundColor: theme.palette.primary.lighter, padding: '1rem' }}
              action={
                isSmallScreen &&
                selectedUser == null && (
                  <IconButton
                    onClick={handleCloseChat}
                    aria-label="settings"
                    disableRipple
                    sx={{
                      backgroundColor: 'transparent',
                      ':hover': { backgroundColor: 'transparent' },
                      ':focus': { backgroundColor: 'transparent' },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
            />

            {/* Scrollable Content Section inside the Card */}
            <CardContent sx={{ flexGrow: 1, overflowY: 'auto', padding: 0 }}>
              {selectedUser === null ? (
                <>
                  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <StyledList
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        padding: '0',
                        height: `calc(100vh - ${matchDownMD ? '130px' : '185px'})`,
                      }}
                      disablePadding
                    >
                      {openContacts ? (
                        <>
                          {contacts && contacts.length > 0 ? (
                            contacts
                              .filter(
                                // (contact) => !chatThreads.some((chatThread) => chatThread?.id === contact.user?.id)
                                (contact) => !chatThreads.some((chatThread) => chatThread?.userDetails?.id === contact.user?.id)
                              )
                              .map((contact) => (
                                <React.Fragment
                                  key={`${contact?.user?.firstName}_+${contact?.user?.lastName}` + contact.id}
                                >
                                  <ListItem key={contact.id} disablePadding>
                                    <ListItemButton
                                      onClick={() => {
                                        handleSelectUser(contact);
                                      }}
                                    >
                                      <ListItemAvatar>
                                        <Avatar alt={`${contact.user.firstName} ${contact.user.lastName}`}>
                                          {!contact.user.profileS3Key &&
                                            `${contact.user.firstName[0]}${contact.user?.lastName[0]}`}{' '}
                                          {/* Fallback initials */}
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <Typography
                                            variant="body1"
                                            noWrap
                                            sx={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            {`${contact.user.firstName} ${contact.user.lastName}`}
                                          </Typography>
                                        }
                                        secondary={
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            noWrap
                                            sx={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            {contact.user.email}
                                          </Typography>
                                        }
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </React.Fragment>
                              ))
                          ) : (
                            <Box
                              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                            >
                              <Typography variant="subtitle2">No Contacts Available</Typography>
                            </Box>
                          )}
                        </>
                      ) : (
                        <>
                          {chatThreads && chatThreads.length > 0 ? (
                            chatThreads
                              .slice()
                              .sort((a, b) => {
                                const dateA = new Date(a.messages[a.messages.length - 1].createdAt);
                                const dateB = new Date(b.messages[b.messages.length - 1].createdAt);
                                return dateB - dateA;
                              })
                              .map((chat, index) => {
                                const unread = chat.messages.filter(
                                  (chat) => chat.sentBy !== loggedInUser?.id && !chat.isRead
                                ).length;
                                return (
                                  <React.Fragment key={`${chat.id}+${index}`}>
                                    <ListItem disablePadding>
                                      <ListItemButton
                                        onClick={() => {
                                          dispatch(setSelectedUser(chat));
                                        }}
                                      >
                                        <ListItemAvatar>
                                          <Avatar src={profilePics[chat?.userDetails.profileS3Key] || ''} />
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary={
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                columnGap: 1,
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-end',
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  display: '-webkit-box',
                                                  WebkitBoxOrient: 'vertical',
                                                  overflow: 'hidden',
                                                  WebkitLineClamp: 2, // Limits to 2 lines
                                                  textOverflow: 'ellipsis',
                                                }}
                                                variant="subtitle1"
                                              >
                                                {chat?.userDetails?.firstName + ' ' + chat?.userDetails?.lastName}
                                              </Typography>
                                              {unread !== 0 && (
                                                <Typography variant="subtitle2">
                                                  {moment(chat.messages[chat.messages.length - 1].createdAt).isSame(
                                                    moment(),
                                                    'day'
                                                  )
                                                    ? moment(chat.messages[chat.messages.length - 1].createdAt).format(
                                                        'HH:mm'
                                                      )
                                                    : moment(chat.messages[chat.messages.length - 1].createdAt).isSame(
                                                        moment().subtract(1, 'day'),
                                                        'day'
                                                      )
                                                    ? `Yesterday`
                                                    : moment(chat.messages[chat.messages.length - 1].createdAt).format(
                                                        'DD/MM/YYYY'
                                                      )}
                                                </Typography>
                                              )}
                                            </Box>
                                          }
                                          secondary={
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                columnGap: 1,
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-end',
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  display: '-webkit-box',
                                                  WebkitBoxOrient: 'vertical',
                                                  overflow: 'hidden',
                                                  WebkitLineClamp: 2, // Limits to 2 lines
                                                  textOverflow: 'ellipsis',
                                                }}
                                                variant="subtitle2"
                                              >
                                                {chat.messages[chat.messages.length - 1].content}
                                              </Typography>
                                              {unread !== 0 && (
                                                <Chip
                                                  sx={{
                                                    height: 24,
                                                    '& .MuiChip-label': { paddingLeft: 1, paddingRight: 1 },
                                                  }}
                                                  label={unread < 100 ? unread : '99+'}
                                                />
                                              )}
                                            </Box>
                                          }
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                  </React.Fragment>
                                );
                              })
                          ) : (
                            <Box
                              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                            >
                              <Typography variant="subtitle2">No Chats Available</Typography>
                            </Box>
                          )}
                        </>
                      )}
                    </StyledList>
                  </Box>
                </>
              ) : (
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                  <StyledList
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      paddingX: 2,
                      maxHeight: `calc(100vh - ${matchDownMD ? '130px' : '185px'})`,
                    }}
                    disablePadding
                  >
                    {selectedUser?.messages &&
                      selectedUser?.messages?.length !== 0 &&
                      selectedUser?.messages.map((chat, index) => {
                        const isLastMessage =
                          index === selectedUser?.messages.length - 1 && unreadMessages.length === 0;
                        if (!chat.isRead && chat.sentBy !== loggedInUser?.id) {
                          unreadMessages.push(chat.id);
                        }
                        const shouldDisplayDate = !dateSection || !moment(dateSection).isSame(chat?.createdAt, 'day');
                        if (shouldDisplayDate) {
                          dateSection = chat?.createdAt;
                        }

                        const shouldDisplayTime =
                          index === 0 ||
                          !moment(selectedUser?.messages[index - 1].createdAt).isSame(chat?.createdAt, 'minute') ||
                          selectedUser?.messages[index - 1].sentBy !== chat?.sentBy;

                        return (
                          <>
                            {shouldDisplayDate && (
                              <Box>
                                <Divider>
                                  <Chip label={moment(chat?.createdAt).format('DD/MM/YYYY')} size="small" />
                                </Divider>
                              </Box>
                            )}
                            {!chat.isRead && unreadMessages.length == 1 && chat.sentBy !== loggedInUser?.id && (
                              <Box>
                                <Divider>
                                  <Chip label="Unread Messages" size="small" />
                                </Divider>
                              </Box>
                            )}
                            {chat.contentType === 'TEXT' && chat.sentBy !== loggedInUser?.id ? (
                              <Box
                                key={`${chat.sentBy}_to_${chat.sentTo}+${index}`}
                                sx={{ marginY: 1.5, mr: 15 }}
                                ref={isLastMessage ? lastMessageRef : null}
                              >
                                {shouldDisplayTime && (
                                  <Box sx={{ display: 'flex', pl: 1 }}>
                                    <Typography variant="caption">{moment(chat?.createdAt).format('HH:mm')}</Typography>
                                  </Box>
                                )}
                                <Box
                                  sx={{
                                    backgroundColor: theme.palette.grey[100],
                                    borderRadius: 2.5,
                                    paddingX: 1.2,
                                    paddingY: 1,
                                    overflow: 'visible',
                                    width: 'fit-content',
                                    filter: 'drop-shadow(0px 0px 0px rgba(0,0,0,0.12))',
                                    '&::before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 11,
                                      left: -4,
                                      width: 12,
                                      height: 9,
                                      bgcolor: theme.palette.grey[100],
                                      transform: 'translateY(-50%) rotate(135deg)',
                                      zIndex: 0,
                                    },
                                  }}
                                >
                                  {renderContent(chat.content)}
                                  {/* <Typography sx={{wordBreak: 'break-word'}}>{chat.content}</Typography> */}
                                </Box>
                              </Box>
                            ) : (
                              chat.contentType === 'TEXT' && (
                                <Box
                                  key={`${chat.sentBy}_to_${chat.sentTo}+${index}`}
                                  sx={{ marginY: 1, pl: 15 }}
                                  ref={isLastMessage ? lastMessageRef : null}
                                >
                                  {shouldDisplayTime && (
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: 1 }}>
                                      <Typography variant="caption">
                                        {moment(chat?.createdAt).format('HH:mm')}
                                      </Typography>
                                    </Box>
                                  )}

                                  <Box
                                    sx={{
                                      ml: 'auto',
                                      width: 'fit-content',
                                      backgroundColor: theme.palette.grey[100],
                                      borderRadius: 2.5,
                                      paddingX: 1.2,
                                      paddingY: 1,
                                      overflow: 'visible',
                                      filter: 'drop-shadow(0px 0px 0px rgba(0,0,0,0.12))',
                                      '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 11,
                                        right: -3,
                                        width: 12,
                                        height: 9,
                                        bgcolor: theme.palette.grey[100],
                                        transform: 'translateY(-50%) rotate(135deg)',
                                        zIndex: 0,
                                      },
                                    }}
                                  >
                                    {renderContent(chat.content)}
                                    {/* <Typography sx={{wordBreak: 'break-word'}}>{chat.content}</Typography> */}
                                  </Box>
                                </Box>
                              )
                            )}
                            {chat.contentType === 'MEETING_REQUESTED' && (
                              <Box
                                sx={{
                                  width: 'fit-content',
                                  ml: 'auto',
                                  mr: 'auto',
                                  my: 2,
                                  backgroundColor: theme.palette.grey[100],
                                  borderRadius: 2.5,
                                  paddingX: 1.5,
                                  paddingY: 1.2,
                                  overflow: 'visible',
                                  filter: 'drop-shadow(0px 0px 0px rgba(0,0,0,0.12))',
                                }}
                                key={`${chat.sentBy}_to_${chat.sentTo}+${index}`}
                                ref={isLastMessage ? lastMessageRef : null}
                              >
                                {renderContent(chat.content)}
                                {!isSubjectLoggedIn && chat.status !== 'EXPIRED' && (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      mt: 2,
                                    }}
                                  >
                                    <CustomButton
                                      variant="outlined"
                                      size="small"
                                      onClick={() => {
                                        sendChat('MEETING_CREATE');
                                      }}
                                    >
                                      Create Meeting
                                    </CustomButton>
                                  </Box>
                                )}
                              </Box>
                            )}
                            {chat.contentType === 'MEETING_CREATE' && (
                              <Box
                                sx={{
                                  width: 'fit-content',
                                  ml: 'auto',
                                  mr: 'auto',
                                  my: 2,
                                  backgroundColor: theme.palette.grey[100],
                                  borderRadius: 2.5,
                                  paddingX: 1.5,
                                  paddingY: 1.2,
                                  overflow: 'visible',
                                  filter: 'drop-shadow(0px 0px 0px rgba(0,0,0,0.12))',
                                }}
                                key={`${chat.sentBy}_to_${chat.sentTo}+${index}`}
                                ref={isLastMessage ? lastMessageRef : null}
                              >
                                {renderContent(chat.content)}
                                {chat.status !== 'EXPIRED' && (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      mt: 2,
                                    }}
                                  >
                                    <CustomButton
                                      variant="outlined"
                                      size="small"
                                      onClick={() => handleMeetingJoin(chat?.payload)}
                                      disabled={isLoading}
                                    >
                                      Join Meeting
                                    </CustomButton>
                                  </Box>
                                )}
                              </Box>
                            )}
                          </>
                        );
                      })}
                  </StyledList>
                </Box>
              )}
            </CardContent>
          </Card>

          {/* Footer Section */}
          {selectedUser && <ChatMessageBox chat={chat} setChat={setChat} isLoading={isLoading} sendChat={sendChat} />}
        </Box>
      </Drawer>
    </>
  );
};

const UserInfo = ({ selectedUser, onBackClick, profilePics }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <IconButton disableRipple onClick={onBackClick}>
      <ArrowBackIosIcon />
    </IconButton>
    <Avatar
      alt={selectedUser?.userDetails?.firstName}
      // src={profilePics[selectedUser?.userDetails?.profileS3Key] || ''}
      sx={{mr:1.5}}
    />
    <Typography variant="subtitle1" color="text.primary">
      {`${selectedUser?.userDetails?.firstName} ${selectedUser?.userDetails?.lastName}`}
    </Typography>
  </Box>
);

const MeetingPopover = ({id, openPopover, anchorEl, onClose, onClick, isSubject }) => (
  <Popover
    id={id}
    open={openPopover}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <Box
      sx={{
        maxWidth: 250,
        paddingX: 2,
        paddingY: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Typography textAlign={'justify'} variant="subtitle1">
        {isSubject
          ? 'Create a telehealth meeting with the subject to discuss the case.'
          : 'Click to Request a meeting with the Clinical Trial Support to discuss your concerns.'}
      </Typography>
      <CustomButton onClick={onClick} sx={{ p: 0.7 }} variant="outlined">
        {isSubject ? 'Create Meeting' : 'Request Meeting'}
      </CustomButton>
    </Box>
  </Popover>
);


export default ChatMessageDrawer;
