import { Box, Typography } from '@mui/material';
import {
  useRosterState
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import AttendeeStatus from './AttendeeStatus';

const AttendeeList = () => {
  const { roster } = useRosterState(); // Get the roster state which includes all attendees

  return (
    <Box>
      <Typography variant="h5">Participants List</Typography>
      {Object.keys(roster).map((attendeeId) => {
        return <AttendeeStatus key={attendeeId} attendeeId={attendeeId} />;
      })}
    </Box>
  );
};

export default AttendeeList;
