import React, { useState } from 'react';
import {
  Dialog,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
  useTheme,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
} from '@mui/material';
import ResetPasswordForm from '../../components/common/resetPasswordForm';
import CustomButton from '../../components/@extended/CustomButton';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useResponsive } from '../../hooks/ResponsiveProvider';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useResendOTPMutation, useVerifyOTPMutation } from '../../store/slices/apiSlice';

function TwoFactorAuthentication({ handleClose }) {
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState('');
  const userData = useSelector(state => state.userDetails.loggedInUser)
  const [verifyOTP, { error1 }] = useVerifyOTPMutation(); 
  const [resendOTP, { error2 }] = useResendOTPMutation();  
  const dispatch = useDispatch()
  const handleRequestOtp = async () => {
    // Placeholder for API call to request OTP
    const result = await resendOTP({
      payload: {
        type:userData?.userMetaInfo?.multiFactorAuth == true
          ? 'DISABLE_2FA'
          : 'ENABLE_2FA'
      },
    });
    if (!result?.error) {
      openMessageNotification({
        message: "OTP Send Successfully",
        type: "success",
      });
    } else {
      openMessageNotification({
        message: result?.error?.data?.message,
        type: "error",
      });
    }
    setOtpRequested(true);
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
  };
  
  const handleOtpSubmit =async() => {
    // Placeholder for API call to verify OTP
    const authStatus = userData?.userMetaInfo?.multiFactorAuth == true
    ? 'DISABLE_2FA'
    : 'ENABLE_2FA'
    if(otp.length===6){
      const result = await verifyOTP({
        payload: {
          otp: otp,
          type :authStatus
        },
      });
      if (!result?.error) {
        openMessageNotification({
          message: `2-Factor Authentication ${authStatus === 'DISABLE_2FA' ? 'disabled' : 'enabled'} successfully!`,
          type: "success",
        });
        handleClose();
      } else {
        openMessageNotification({
          message: result?.error?.data?.message,
          type: "error",
        });
      }
    }
    else{
      openMessageNotification({
        message: "Please enter the OTP to proceed",
        type: "error",
      });
    }
    console.log('Submitting OTP:', otp);
  };

  return (
    <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="h6" gutterBottom textAlign="center" width="100%">
        {userData?.userMetaInfo?.multiFactorAuth == true
          ? 'Disable 2-Factor Authentication'
          : 'Enable 2-Factor Authentication'}
      </Typography>
      <Typography variant="body2" color="textSecondary" textAlign="center">
        Secure your account with Two-Factor Authentication (2FA).
      </Typography>
      <Typography variant="body2" color="textSecondary" textAlign="center" sx={{ mb: 3 }}>
        Click "Request OTP" to receive a one-time password on your registered mobile number.
      </Typography>

      {!otpRequested ? (
        <Box sx={{ height:"100%", flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '100%' }}>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleRequestOtp}
              sx={{
                position: 'relative',
                bottom: 0,
              }}
            >
              Request OTP
            </CustomButton>
          </Box>
        </Box>
      ) : (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column' }}>
              <Typography variant="body1" gutterBottom pb={1} textAlign={'center'}>
                Enter the OTP sent to your registered mobile number:
              </Typography>
              <MuiOtpInput
                length={6}
                value={otp}
                onChange={handleOtpChange}
                autoFocus
                TextFieldsProps={{
                  sx: { width: '3rem', marginRight: '0.5rem' },
                }}
                validateChar={(val) => !isNaN(val)}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CustomButton variant="contained" color="primary" onClick={handleOtpSubmit} sx={{ mt: 2 }}>
              Submit
            </CustomButton>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function AccountSettingsDialog({ open, handleClose }) {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const { isSmallScreen } = useResponsive();
  
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={'md'}
      //sx={{minHeight: '50%'}}
      PaperProps={{ sx: { minHeight: '50%', minWidth: isSmallScreen ? '95%' : '50%' } }}
    >
      <DialogTitle sx={{ pr: 1 }}>
        <Tabs value={activeTab} onChange={handleTabChange} centered variant="fullWidth">
          <Tab label="Reset Password" />
          <Tab label="2FA Settings" />
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              ml: 2,
              color: (theme) => theme.palette.grey[500],
            }}
            disableFocusRipple
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </Tabs>
      </DialogTitle>
      <Box sx={{ p: 2 }}>
        {/* <Box sx={{ mt: isSmallScreen ? 0 : 3 }}> */}
        {activeTab === 0 && <ResetPasswordForm handleClose={handleClose} tempPasswordLabel={'Current Password'} />}
        {activeTab === 1 && <TwoFactorAuthentication handleClose={handleClose} />}
        {/* </Box> */}
      </Box>
    </Dialog>
  );
}

export default AccountSettingsDialog;
