import { Paper, Table, TableCell, TableContainer, TableRow, TableBody, Typography, useTheme, Box } from '@mui/material';
import { CustomTableFoot, CustomTableHead, CustomTableHeadCell, CustomTableRow } from '../../components/@extended/CustomTable';
import { useParams } from 'react-router-dom';
import { useGetAllSponserConfigurationsQuery, useGetTrailSysQuery } from '../../store/slices/apiSlice';
import { Columns3 } from './TrailDetailsTableColumns';
import RightArrow from '../../components/icons/RightArrow';
import CustomButton from '../../components/@extended/CustomButton';
import { useNavigate } from 'react-router-dom';
import CustomTablePagination from '../../components/common/TablePagination';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePagination } from '../../store/slices/applicationSlice';
import { openMessage } from '../../store/slices/showMessageSlice';
import CustomCard from '../../components/@extended/CustomCard';

const TrailDetailsTable = () => {
  const theme = useTheme();
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const columns = Columns3();
  const { sponsorId } = useParams();
  const { data: sponserConfigurations } = useGetAllSponserConfigurationsQuery(sponsorId);
  const {page,rowsPerPage} = useSelector((state) => state.application.pagination.TRAIL_DETAILS_TABLE);
  const { data: data1, isLoading } = useGetTrailSysQuery(sponsorId);
  if (isLoading) {
    return <></>;
  }

  const onNewPage = (page, rowsPerPage) =>{
    dispatch(updatePagination({tableType:'TRAIL_DETAILS_TABLE',page,rowsPerPage}));
  }

  const handleCreate = ()=>{
    if(sponserConfigurations && sponserConfigurations.length >0 ){
      navigate(`/sponsor/createTrial/${sponsorId}`)
    }else{
      dispatch(openMessage({ messageSeverity: 'error', message: 'At least one configuration need to assign to sponser.' }));
    }
  }

  return (
    <>
      <CustomCard
        title={
          <Typography variant="h6" fontWeight={600} color={theme.palette.grey[800]}>
            Trials
          </Typography>
        }
        //   action={
        // <CustomButton variant='contained' onClick={handleCreate}>
        //     + Create Trial
        //   </CustomButton>
        //   }
      >
        <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
          <Table stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <TableRow>
                {columns.map((column) => (
                  <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column.style }}>
                    {column.label}
                  </CustomTableHeadCell>
                ))}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {data1 && data1.length > 0 ? (
                (rowsPerPage > 0 ? data1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data1).map(
                  (row) => (
                    <CustomTableRow key={row?.id} hover role="checkbox" tabIndex={-1}>
                      <TableCell sx={{ maxWidth: 350 }}>
                        <Typography align="justify">{row?.trialTitle}</Typography>
                      </TableCell>
                      <TableCell>{row?.protocolNumber}</TableCell>
                      <TableCell align="center">{row?.protocolVersion}</TableCell>
                      <TableCell align="center">{row?.totalSubjectNumber}</TableCell>
                      {/* <TableCell align="right">
                      <RightArrow />
                    </TableCell> */}
                    </CustomTableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <Typography variant="subtitle1" color="initial">
                      No Trail Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <CustomTableFoot>
              <CustomTableRow sx={{ right: 0 }}>
                <CustomTableHeadCell colSpan={columns.length}>
                  <CustomTablePagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={data1?.length}
                    onNewPage={onNewPage}
                  />
                </CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableFoot>
          </Table>
        </TableContainer>
      </CustomCard>
    </>
  );
};

export default TrailDetailsTable;
