import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomTableFoot,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableRow,
} from "../../components/@extended/CustomTable";
import { bloqcibeApi, useCancelVisitSlotMutation } from "../../store/slices/apiSlice";
import { SubjectDetailColumns } from "./SubjectDetailColumns";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CreateSchedule from '../booking/CreateSchedule';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import CustomTablePagination from '../../components/common/TablePagination';
import { getUserRole } from '../util';
import { updatePagination } from '../../store/slices/applicationSlice';
  
  const BookedVisitSlots = () => {
    const navigate = useNavigate();
    const columns1 = SubjectDetailColumns();
    const theme = useTheme();
    const dispatch = useDispatch()
    const [createScheduleOpen, setCreateScheduleOpen] = useState(false)
    const [selectedSlot, setSelectedSlot] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const userRole = getUserRole(user);
    const {page,rowsPerPage} = useSelector((state) => state.application.pagination.BOOKED_VISIT_SLOTS);
    const [getBookedTrialsSlots, { data: slotDetails }] = bloqcibeApi.endpoints.getBookedTrialsSlots.useLazyQuery();
    const [cancelVisitSlot] = useCancelVisitSlotMutation();
      const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        message: '',
        buttonLabel: 'Confirm',
      });
    useEffect(() => {
      (async () => {
        const bookedSlots = await getBookedTrialsSlots({
          limit: 10,
          offset: 0
      })})()
    }, [])
    
    const rows = useMemo(() => {
      if (slotDetails) {
        let rowsData = slotDetails?.bookedSlots?.map((_slt) => {
          return {
            id: _slt?.id,
            protocolNumber:_slt?.slot?.trial?.protocolNumber,
            subjectName: userRole==='site_monitor' ? _slt?.user?.subjectNumber : _slt?.user?.firstName + ' ' + _slt?.user?.lastName,
            date: moment(_slt.slot.date).format("MM-DD-YYYY"),
            visitTitle: _slt?.visit?.stepTitle,
            startTime: _slt.slot.startTime,
            endTime: _slt.slot.endTime,
          };
        });
        rowsData = rowsData?.filter((row)=> moment(row.date).isSameOrAfter(moment().format("MM-DD-YYYY")) && (moment(row.date).isSame(moment().format("MM-DD-YYYY")) ? moment(row.startTime, 'HH:mm:ss').isAfter(moment()) : true));
        return rowsData
      } else {
        return []
      }
    }, [slotDetails]);

  const timeConverter = (time) => {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  };

      const handleConfirmDialogClose = () => {
        setConfirmDialog({
          open: false,
          message: '',
          buttonLabel: 'Confirm',
        });
          setSelectedSlot(null);
      };
    
    const handleConfirmatioDialogOpen = (row) => {
          const slot = slotDetails.bookedSlots?.find((_slt) => _slt.id === row.id);
          setSelectedSlot(slot);
        setConfirmDialog({
          open: true,
          message: (
            <>
              <span>Are you sure you want to cancel this slot?</span>
              <br />
              <br />
              <div style={{ display: 'flex',flexDirection:'column', rowGap: 4 }}>
                <div>
                  <span style={{ fontWeight: 600 }}>Subject Name:</span>&nbsp;&nbsp;
                  <span>{row?.subjectName}</span>
                </div>
                <div>
                  <span style={{ fontWeight: 600 }}>Date:</span>&nbsp;&nbsp;
                  <span>{moment(row?.date).format('MMMM DD YYYY')}</span>
                </div>
                <div>
                  <span style={{ fontWeight: 600 }}>Time:</span>&nbsp;&nbsp;
                  <span>{timeConverter(row?.startTime)}</span> - <span>{timeConverter(row?.endTime)}</span>
                </div>
              </div>
            </>
          ),
          buttonLabel: 'Confirm',
        });
      };

    const handleCreateScheduleClose = () => {
      setCreateScheduleOpen(false);
      setSelectedSlot(null);
    }

    const createSchedule = (row) => {
      const slot = slotDetails.bookedSlots?.find((_slt) => _slt.id === row.id);
      setSelectedSlot(slot)
      setCreateScheduleOpen(true);
    }

    const handleSlotCancelation = async () => {
        setConfirmDialog({
          open: false,
          message: '',
          buttonLabel: 'Confirm',
        });
        const res = await cancelVisitSlot({ slotId:selectedSlot.slot.id, userId:selectedSlot.userId });
        if (res.data) {
          dispatch(
            openMessage({
              messageSeverity: 'success',
              message: 'Booking is cancelled.',
            })
          );
        } else {
          dispatch(
            openMessage({
              messageSeverity: 'error',
              message: res.error.data.message,
            })
          );
        }
      };

      const onNewPage = (page, rowsPerPage) =>{
        dispatch(updatePagination({tableType:'BOOKED_VISIT_SLOTS',page,rowsPerPage}));
      }
    return (
      <>
        <Typography variant="h6" color="initial" sx={{ pb: 2 }}>
          Visit Appointments
        </Typography>
        <Paper sx={{ maxWidth:'100vW', width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              height: 3,
              backgroundColor: theme.palette.primary.light,
              borderRadiusTop: 5,
            }}
          />
          <TableContainer sx={{ flexGrow: 1, overflow: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <CustomTableHead>
                <TableRow>
                  {columns1.map((column) => {
                    let labelValue = null;
                    switch (column.id) {
                      case 'trialTitle': {
                        labelValue = (
                          <div>
                            {column.label}
                            <br />
                            {'Start Date'}
                          </div>
                        );
                        break;
                      }
                      default:
                        labelValue = column.label;
                        break;
                    }
                    return (
                      <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column?.style }}>
                        {labelValue}
                      </CustomTableHeadCell>
                    );
                  })}
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {rows && rows.length > 0 ? (
                  (rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
                  ).map((row) => {
                    return (
                      <CustomTableRow onClick={() => navigate(``)} hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns1.map((column) => {
                          let value = null;
                          const style = {};
                          switch (column.id) {
                            default:
                              value = row[column.id];
                              break;
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Typography variant="subtitle2" color="initial">
                                {value}
                              </Typography>

                              {column.id === 'action' && (
                                <>
                                  <EditCalendarIcon
                                    onClick={() => createSchedule(row)}
                                    sx={{ color: theme.palette.primary.main, mx: 1, cursor: 'pointer' }}
                                  />
                                  <EventBusyIcon
                                    sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                    onClick={() => handleConfirmatioDialogOpen(row)}
                                  />
                                </>
                              )}
                            </TableCell>
                          );
                        })}
                      </CustomTableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns1.length} align="center">
                      <Typography variant="subtitle1" color="initial">
                        No Visit Appointments Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <CustomTableFoot>
                <CustomTableRow sx={{ right: 0 }}>
                  <CustomTableHeadCell colSpan={columns1.length}>
                    <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={rows?.length} onNewPage={onNewPage} />
                  </CustomTableHeadCell>
                </CustomTableRow>
              </CustomTableFoot>
            </Table>
          </TableContainer>
        </Paper>
        <ConfirmationDialog
          open={confirmDialog.open}
          buttonLabel={confirmDialog.buttonLabel}
          message={confirmDialog.message}
          handleClose={handleConfirmDialogClose}
          handleConfirm={handleSlotCancelation}
        />
        <CreateSchedule open={createScheduleOpen} handleClose={handleCreateScheduleClose} bookingSlot={selectedSlot} />
      </>
    );
  };
  
  export default BookedVisitSlots;
  