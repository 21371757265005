import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const useChatMessage = () => {
  const chatThreads = useSelector((state) => state.chats.chatThreads);
  const loggedInUser = useSelector((state) => state.userDetails?.loggedInUser);

  const unreadMessagesCount = useMemo(() => {
    return chatThreads.reduce((total, user) => {
      const unreadMessages = user.messages.filter((chat) => chat.sentBy !== loggedInUser?.id && !chat.isRead).length;
      return total + unreadMessages;
    }, 0);
  }, [chatThreads, loggedInUser]);

  return { unreadMessagesCount };
};

export default useChatMessage;
