// material-ui
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from "react-router-dom";
import { useLoginMutation, useVerifyAuthOTPMutation } from "../../store/slices/apiSlice";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import logo from "../../assets/images/logo/bitmap@2x.jpg";
import vector from "../../assets/images/login/undrawMedicineB1Ol@3x.png";
import pfas from "../../assets/images/login/pFas.png";
import earth from "../../assets/images/login/earth-removebg-preview.png";
import esg from "../../assets/images/login/esg-removebg-preview.png";
import CustomButton from "../../components/@extended/CustomButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import ShowMessage from "../../components/showMessage/ShowMessage";
import { useDispatch } from "react-redux";
import { openMessage } from "../../store/slices/showMessageSlice";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useResponsive } from "../../hooks/ResponsiveProvider";


import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


const Login = () => {
  const [login] = useLoginMutation();
  const [verifyAuthOtp] = useVerifyAuthOTPMutation()
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSmallScreen ,isMediumScreen} = useResponsive();
  const [openOTPPanel, setOpenOTPPanel] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const docSchema = Yup.object().shape({
    userId: Yup.string()
      .required('Username/Email Id is required')
      .email('Enter valid Username/Email Id.')
      .matches(/^[a-z0-9.-]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Email address can only contain lowercase letters, numbers, and periods.')
      .strict(true),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      userId: "",
      password: "",
    },
    validationSchema: docSchema,
    onSubmit: async (values) => {
      const result = await login({
        userId: values.userId,
        password: values.password,
      });
      if (!result?.error) {
        if(result.data?.access_token){
          navigate("/");
        } else {
          dispatch(
            openMessage({
              messageSeverity: "success",
              message: "OTP is sent on your registered email and mobile number!",
            })
          );
          setOpenOTPPanel(true)
        }
      } else {
        openMessageNotification({
          message: result?.error?.data?.error_description,
          type: "error",
        });
      }
    },  
  });

  useEffect(() => {
    // Function to handle the countdown logic
    const interval = setInterval(() => {
      // Decrease seconds if greater than 0
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      // When seconds reach 0, decrease minutes if greater than 0
      if (seconds === 0) {
        if (minutes === 0) {
          // Stop the countdown when both minutes and seconds are 0
          clearInterval(interval);
        } else {
          // Reset seconds to 59 and decrease minutes by 1
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000); // Run this effect every 1000ms (1 second)

    return () => {
      // Cleanup: stop the interval when the component unmounts
      clearInterval(interval);
    };
  }, [seconds]); // Re-run this effect whenever 'seconds' changes

  // Function to resend OTP
  const resendOTP = async () => {
    const val = await login({
      userId: formik.values.userId,
      password: formik.values.password,
    });
    if (val.data) {
      setMinutes(1);
      setSeconds(59);
    }
  };
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleVerifyOTP = async () => {
    const result = await verifyAuthOtp({
      userId: formik.values.userId,
      password: formik.values.password,
      otp,
    });
    if (!result?.error) {
      navigate('/');
    } else {
      openMessageNotification({
        message: result?.error?.data?.message,
        type: 'error',
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    formik.handleSubmit();
  };

  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
  };

  const handleBackToLogin = () => {
    setOpenOTPPanel(false);
  };

  // const handelVerifiedOTP = () => {
  //     navigate("/");
  // };
  return (
    <>
      <Grid container sx={{ height: '100vh', width: '100%' }}>
        <Box
          sx={{
            p: 4,
            display: 'flex',
            justifyContent: isMediumScreen ? 'center' : 'flex-end',
            position: 'absolute',
            right: 0,
          }}
        >
          <Button
            size="large"
            variant="outlined"
            sx={{ textTransform: 'none', color: theme.palette.primary.light }}
            startIcon={<HelpOutlineOutlinedIcon sx={{ color: theme.palette.primary.light }} />}
          >
            Help & Support
          </Button>
        </Box>
        <Grid
          item
          md={6}
          //sm={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMediumScreen ? 'center' : 'flex-end',
            ...(isMediumScreen && {
              alignItems: 'center',
            }),
          }}
        >
          <Paper
            sx={{
              height: isMediumScreen ? 'auto' : 550,
              ...(!isMediumScreen && {
                width: 480,
              }),
            }}
            elevation={isMediumScreen ? 0 : 5}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 5 }}>
              <img src={logo} alt="Bloqcube" width="200" />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4, paddingTop: isMediumScreen ? 8 : 4 }}>
              <Typography variant="h6" color="initial">
                Login to your account
              </Typography>
            </Box>
            {openOTPPanel ? (
              <>
                {/* <LoginOTP handleBackToLogin={handleBackToLogin} handelVerifiedOTP={handelVerifiedOTP} /> */}
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifycontent: 'center',
                  }}
                >
                  <Box sx={{ paddingX: 3, py: 2 }}>
                    <Typography variant="body1" color="#5d5d5d" pb={1}>
                      Enter OTP sent on your registered Email
                    </Typography>
                    <MuiOtpInput value={otp} onChange={handleChange} length={6} validateChar={(val) => !isNaN(val)}/>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '8px 0px',
                      }}
                    >
                      {/* Display countdown timer if seconds or minutes are greater than 0 */}

                      {seconds > 0 || minutes > 0 ? (
                        <p>
                          Time Remaining:{' '}
                          <span style={{ fontWeight: 600 }}>
                            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                          </span>
                        </p>
                      ) : (
                        // Display if countdown timer reaches 0
                        <p>Didn't receive code?</p>
                      )}

                      {/* Button to resend OTP */}
                      <Button
                        disabled={seconds > 0 || minutes > 0}
                        sx={{
                          color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                          textDecoration: 'underline',
                        }}
                        onClick={resendOTP}
                      >
                        Resend OTP
                      </Button>
                    </Box>

                    {/* Button to submit OTP */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 3 }}>
                      <Button
                        variant="text"
                        sx={{ width: 150, color: theme.palette.primary.light }}
                        size="large"
                        onClick={() => {
                          handleBackToLogin();
                        }}
                      >
                        Back to login
                      </Button>
                      <CustomButton
                        disabled={!otp || otp.length < 6}
                        variant="contained"
                        onClick={handleVerifyOTP}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' && otp.length < 6) {
                            event.preventDefault();
                            handleVerifyOTP();
                          }
                        }}
                      >
                        Verify OTP
                      </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <form onSubmit={handleFormSubmit}>
                  <Grid container rowSpacing={3}>
                    <Grid item xs={12} px={3}>
                      <Typography variant="body1" color="#5d5d5d" pb={1}>
                        Username/Email Id
                      </Typography>
                      <TextField
                        name="userId"
                        fullWidth
                        placeholder="Username/Email Id"
                        onChange={formik.handleChange}
                        value={formik.values.userId}
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.userId && formik.errors.userId}
                        error={formik.touched.userId && Boolean(formik.errors.userId)}
                      />
                    </Grid>
                    <Grid item xs={12} px={3}>
                      <Typography variant="body1" color="#5d5d5d" pb={1}>
                        Password
                      </Typography>
                      <TextField
                        name="password"
                        fullWidth
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.password && formik.errors.password}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" sx={{ pr: 1 }}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', px: 3 }}>
                      <Button
                        variant="text"
                        sx={{ width: 150, color: theme.palette.primary.light }}
                        size="large"
                        onClick={() => {
                          navigate('/forgotPassword');
                        }}
                      >
                        Forgot password?
                      </Button>

                      <CustomButton
                        // onClick={formik.handleSubmit}
                        size="large"
                        type="submit"
                        sx={{
                          width: 150,
                          backgroundColor: theme.palette.primary.light,
                        }}
                      >
                        Login
                      </CustomButton>
                    </Grid>
                  </Grid>
                </form>
              </>
            )}
          </Paper>
        </Grid>
        {!isMediumScreen && (
          <Grid item md={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Swiper style={{ height: 550, width: 480, marginLeft: 0 }}>
              <SwiperSlide style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <img src={vector} alt="Bloqcube" width="95%" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={pfas} alt="Bloqcube" width="100%" height={'100%'} style={{ borderRadius: 10 }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={earth} alt="Bloqcube" width="100%" height={'100%'} style={{ borderRadius: 10 }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={esg} alt="Bloqcube" width="100%" height={'100%'} style={{ borderRadius: 10 }} />
              </SwiperSlide>
            </Swiper>
          </Grid>
        )}
      </Grid>
      <ShowMessage />
    </>
  );
};

export default Login;