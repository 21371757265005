import React from "react";
import TextFieldContainer from "../../../components/inputContainers/TextFieldContainer";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  useTheme,
  Typography,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { getIn } from "formik";
import { RemoveOutlined } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CustomButton from "../../../components/@extended/CustomButton";
import { useGetAllConfigurationsQuery, useUpdateTrialBasicDetailsMutation } from "../../../store/slices/apiSlice";
import { useDispatch } from "react-redux";
import { openMessage } from "../../../store/slices/showMessageSlice";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from "moment";
import dayjs from "dayjs";

const TrialDetailsSection = ({ formik, theraeupticAgents, trialId, sponsorId }) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const [updateTrialBasicDetails] = useUpdateTrialBasicDetailsMutation();
  const { data: configurations, isLoading } = useGetAllConfigurationsQuery();
  const handleUpdate =async () => {
    await formik.submitForm();
    if (formik.isValid) {
    const result = await updateTrialBasicDetails({
      sponsorId,
      trialId,
      trialPayload: formik.values,
    });
      if (result.data) {
        dispatch(openMessage({ message: 'Submitted Successfully', messageSeverity: 'success' }));
      } else if (result.error) {
        dispatch(openMessage({ message: result.error, messageSeverity: 'error' }));
      }
    }
  };

  const handleDateChange = (date) => {
        const formatedDate = dayjs(date).format('MM/DD/YYYY');
        formik.setFieldValue('protocolVersionDate', formatedDate, true);
      };
  return (
    <Card>
      <CardHeader title={'Study Summary'} titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <Grid container>
          <Grid item sm={12} md={5} sx={{ pb: 2 }}>
            <TextFieldContainer
              name={'protocolNumber'}
              placeholder={'Enter Protocol Number'}
              label={'Protocol Number'}
              formik={formik}
            />
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          <Grid item sm={12} md={5} sx={{ pb: 2 }}>
            <TextFieldContainer
              name={'protocolVersion'}
              placeholder={'Enter Protocol Version'}
              label={'Protocol Version'}
              formik={formik}
            />
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          <Grid item sm={12} md={5} sx={{ py: 2 }}>
            <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
              Protocol Version Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(
                  new Date(
                    moment(
                      getIn(formik.values, 'protocolVersionDate') && getIn(formik.values, 'protocolVersionDate'),
                      'MM/DD/YYYY'
                    )
                  )
                )}
                error={getIn(formik.touched, 'protocolVersionDate') && getIn(formik.error, 'protocolVersionDate')}
                slotProps={{
                  textField: {
                    error:
                      getIn(formik.touched, 'protocolVersionDate') &&
                      getIn(formik.error, 'protocolVersionDate') &&
                      true,
                    helperText:
                      getIn(formik.touched, 'protocolVersionDate') && getIn(formik.error, 'protocolVersionDate'),
                    name: 'protocolVersionDate',
                    fullWidth: true,
                    size: 'small',
                  },
                }}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          <Grid item sm={12} md={5} sx={{ py: 2 }}>
            <TextFieldContainer
              name={'trialTitle'}
              placeholder={'Enter Trial Title'}
              label={'Trial Title'}
              formik={formik}
            />
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          <Grid item sm={12} md={5} sx={{ py: 1 }}>
            <TextFieldContainer
              name={'totalSubjectNumber'}
              placeholder={'Enter Number of Subjects'}
              label={'Number of Subjects'}
              formik={formik}
              type={'number'}
            />
          </Grid>
          <Grid item sm={12} sx={{ pb: 2, py: 2 }}>
            <Divider />
          </Grid>
          <Grid item sm={12} md={5} sx={{ pb: 2 }}>
            <FormConfigurationField
              formik={formik}
              name={'siteInitiationForm'}
              configuration={configurations?.find((config) => config.key === 'siteInitiationForm')}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          <Grid item sm={12} md={5} sx={{ pb: 2 }}>
            <FormConfigurationField
              formik={formik}
              name={'eConsentForm'}
              configuration={configurations?.find((config) => config.key === 'eConsentForm')}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          <Grid item sm={12} md={5} sx={{ pb: 2 }}>
            <FormConfigurationField
              formik={formik}
              name={'crfForm'}
              configuration={configurations?.find((config) => config.key === 'crfForm')}
              isLoading={isLoading}
            />
          </Grid>

          <Grid item sm={12} sx={{ pb: 2, py: 2 }}>
            <Divider />
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          {formik.values.theraeupticAgents.map((_, index) => {
            return (
              <Grid
                container
                direction={{ md: 'row', sm: 'column' }}
                // columnSpacing={3}
                sx={{ py: 1.5 }}
                key={index}
              >
                <Grid item sm={12} md={5}>
                  <TextFieldContainer
                    name={`theraeupticAgents.${index}.agentName`}
                    placeholder={'Enter Name of Study Drug'}
                    label={'Name of Study Drug'}
                    formik={formik}
                  />
                </Grid>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} md={5}>
                  <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
                    Class of Study Drug
                  </Typography>
                  <FormControl
                    sx={{ width: '100%' }}
                    error={
                      getIn(formik.touched, `theraeupticAgents.${index}.agentClass`) &&
                      Boolean(getIn(formik.errors, `theraeupticAgents.${index}.agentClass`))
                    }
                  >
                    <Select
                      displayEmpty
                      name={`theraeupticAgents.${index}.agentClass`}
                      value={getIn(formik.values, `theraeupticAgents.${index}.agentClass`)}
                      onChange={formik.handleChange}
                      fullWidth={true}
                      input={<OutlinedInput size="small" />}
                    >
                      <MenuItem disabled value="">
                        <em style={{ color: '#aaa', fontStyle: 'normal' }}>Select Class of Study Drug</em>
                      </MenuItem>
                      <MenuItem value="Oral">Oral</MenuItem>
                      <MenuItem value="Injectable">Injectable</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    <FormHelperText>
                      {getIn(formik.touched, `theraeupticAgents.${index}.agentClass`) &&
                        getIn(formik.errors, `theraeupticAgents.${index}.agentClass`)}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={1}>
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',

                      pt: 3.5,
                    }}
                  >
                    {index <= 0 && formik.values.theraeupticAgents.length !== 3 && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (formik.values.theraeupticAgents.length < 3) {
                            formik.values.theraeupticAgents.push(theraeupticAgents);
                            formik.setFieldValue('theraeupticAgents', formik.values.theraeupticAgents);
                          }
                        }}
                        sx={{ bgcolor: theme.palette.grey[200] }}
                      >
                        <AddOutlinedIcon fontSize={'18'} color={theme.palette.common.white} />
                      </IconButton>
                    )}
                    {index > 0 && (
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          formik.values.theraeupticAgents.splice(index, 1);
                          formik.setFieldValue('theraeupticAgents', formik.values.theraeupticAgents);
                        }}
                        sx={{ bgcolor: theme.palette.grey[200] }}
                      >
                        <RemoveOutlined fontSize="inherit" color={theme.palette.common.white} />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            );
          })}
          {trialId && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                sx={{
                  py: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  pr: 5,
                }}
              >
                <CustomButton
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleUpdate}
                  sx={{ color: 'white' }}
                >
                  Update
                </CustomButton>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TrialDetailsSection;

const FormConfigurationField = ({ formik, name, configuration, isLoading }) => {
  const theme = useTheme();

  const handleSelectChange = (event) => {
    formik.setFieldValue(name, event.target.value);
  };

  return (
    <>
      <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
        {configuration?.label}
      </Typography>
      <FormControl sx={{ width: '100%' }} error={getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))}>
        <Select
          displayEmpty
          name={name}
          value={getIn(formik.values, name) || ''} // Ensure the value is not undefined
          onChange={handleSelectChange}
          fullWidth
          input={<OutlinedInput size="small" />}
        >
          <MenuItem disabled value="">
            <em style={{ color: '#aaa', fontStyle: 'normal' }}>
              {!isLoading && configuration?.options?.length > 0
                ? `Select ${configuration?.label}`
                : 'No configuration Found'}
            </em>
          </MenuItem>
          {!isLoading && configuration?.options?.map((conf) => (
            <MenuItem key={conf.key} value={conf.key}>
              {conf.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{getIn(formik.touched, name) && getIn(formik.errors, name)}</FormHelperText>
      </FormControl>
    </>
  );
};
