import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  Typography,
  useTheme,
  Box,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomTableFoot,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableRow,
} from "../../components/@extended/CustomTable";
import CustomButton from "../../components/@extended/CustomButton";
import RightArrow from "../../components/icons/RightArrow";
import { useNavigate } from "react-router-dom";
import { useGetOngoingTrialsQuery } from "../../store/slices/apiSlice";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Columns } from "./TrialDetailColumns";
import { getUserRole } from "../util";
import { selectCurrentUser } from "../../store/slices/authSlice";
import CustomTablePagination from "../../components/common/TablePagination";
import { updatePagination } from "../../store/slices/applicationSlice";

const OnGoingTrialsTable = () => {
  const navigate = useNavigate();
  const isOngoingTable = true;
  const columns1 = Columns(isOngoingTable);
  const theme = useTheme();
  const dispatch = useDispatch();
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const {data, error, isLoading} = useGetOngoingTrialsQuery(sponsorId);
  const user = useSelector(selectCurrentUser);
  const userRole = getUserRole(user);
  const {page,rowsPerPage} = useSelector((state) => state.application.pagination.ONGOING_TRIALS_TABLE);


  const onNewPage = (page, rowsPerPage) =>{
    dispatch(updatePagination({tableType:'ONGOING_TRIALS_TABLE',page,rowsPerPage}));
  }

  return (
    <>
      <Grid item sm={12} sx={{ display: "flex", justifyContent:"space-between",alignItems:"center",py:3}}>
        <Typography variant="h6" color="initial">
          Ongoing Trials
        </Typography>
        {userRole=="sponsor" && <CustomButton
          variant="contained"
          onClick={() => navigate("/createTrial")}
        >
          <Typography variant="subtitle1" color={theme.palette.common.white}>
            + Create New Trial
          </Typography>
        </CustomButton>}
      </Grid>
      <Paper sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: 3,
            backgroundColor: theme.palette.primary.light,
            borderRadiusTop:5
          }}
        />
        <TableContainer sx={userRole==='sponsor' ? { flexGrow: 1, overflow: "auto",maxWidth:'100vW' } : {maxHeight: 440, maxWidth:'100vW'} }>
          <Table stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <TableRow >
                {columns1.map((column, index) => {
                  let labelValue = null;
                  switch(column.id) {
                      case "trialTitle": {
                        labelValue = <div>
                              {column.label}<br/>
                              {"Start Date" }
                        </div>
                        break;
                      }
                    default:
                      labelValue = column.label;
                        break;
                  }
                  return (
                    <CustomTableHeadCell key={column.id + index} align={column.align} sx={{ ...column?.style }}>
                      {labelValue}
                    </CustomTableHeadCell>
                  );
                  })}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {data && data.length > 0 ? (
                (rowsPerPage > 0
                  ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : data
                ).map((row) => {
                  return (
                    <CustomTableRow
                      key={row.id} 
                      hover 
                      role="checkbox" 
                      tabIndex={-1} 
                      onClick={()=>{navigate(`/trial/${row.id}`)}} 
                      sx={{cursor:'pointer'}}
                    >
                      {columns1.map((column, index) => {
                        let value = null;
                        const style = {};
                        switch (column.id) {
                          case "createdAt": {
                            value = moment(row[column.id]).format(
                              "DD/MM/YYYY, hh:mm"
                            );
                            break;
                          }
                          
                          case "trialTitle": {
                            value = 
                              <div style={{diplay:'flex', justifyContent:'center'}}>
                              <Typography variant="subtitle2" color="initial">
                              {row.trialTitle}
                              </Typography>
                              <Typography variant="subtitle2" color="initial">
                              {moment(row.updatedAt).format("DD/MM/YYYY")}
                              </Typography>
                              </div>
                            break;
                          }
                          case "currentExpenditure": {
                            value = "--"  
                            break;
                          }
                          
                          default:
                            value = row[column.id];
                            break;
                        }
                        return (
                          <TableCell key={column.id + index} align={column.align}>
                            <Typography variant="subtitle2" color="initial">
                              {value}
                            </Typography>

                            {column.id === 'action' && <RightArrow onClick={() => navigate(`/trial/${row.id}`)} />}
                          </TableCell>
                        );
                      })}
                    </CustomTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns1.length} align="center">
                    <Typography variant="subtitle1" color="initial">
                      No Ongoing Trials Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {userRole === 'sponsor' ?
              <CustomTableFoot>
                <CustomTableRow sx={{ right: 0 }}>
                  <CustomTableHeadCell colSpan={columns1.length}>
                    <CustomTablePagination page={page} rowsPerPage={rowsPerPage} count={data?.length} onNewPage={onNewPage} />
                  </CustomTableHeadCell>
                </CustomTableRow>
              </CustomTableFoot>
              : ''}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default OnGoingTrialsTable;
