import { useTheme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

function WebsiteLogo({ url }) {
  const [urlData, setUrlData] = useState({
    logo : '',
    domain : ''
  });
  const theme = useTheme()

  useEffect(() => {
    const fetchLogo = () => {
      try {
        const domain = new URL(url).hostname;
        const logo = `https://logo.clearbit.com/${domain}`;
        setUrlData({domain,logo});
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, [url]);

  const handleClick = () => {
    if (url) { // Ensure urlData and urlData.url are defined
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Button disableRipple fullWidth sx={{padding:0}} onClick={handleClick}>
      {urlData?.logo && 
      <Box 
      sx={{display:'flex' , 
      alignItems:'flex-end' , 
      columnGap:2, 
      backgroundColor: theme.palette.grey[300],
      p:0.5 , 
      width:'100%',
      borderRadius:1
      }}>
        <img src={urlData.logo} alt="Website Logo" style={{ width: 50, height: 50 , borderRadius:2 }} />
        <Typography sx={{mr:1 , color:'common.black'}}>{urlData.domain}</Typography>
      </Box>
      }
    </Button>
  )
}

export default WebsiteLogo;
