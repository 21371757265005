import {
    GlobalStyles,
    MeetingProvider,
    UserActivityProvider,
    lightTheme,
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import JoinMeeting from './JoinMeeting';
const Meeting = () => {
  return (
    <>
      <MeetingProvider>
        <Theme>
          <UserActivityProvider>
            <JoinMeeting />
          </UserActivityProvider>
        </Theme>
      </MeetingProvider>
    </>
  );
};

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Meeting;
