
import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider, Grid, IconButton, Typography, styled } from '@mui/material';
import CustomButton from "../../components/@extended/CustomButton";
import { useGetMeUserQuery } from '../../store/slices/apiSlice';
import ResetPasswordForm from "../../components/common/resetPasswordForm"
import ProfilePic from '../../components/common/profilePic';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountSettingsDialog from './AccountSettingsDialog';
const Input = styled('input')({
    display: 'none',
});

function AccountDetails() {
    const {data:userData} = useGetMeUserQuery();
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    
    const handleResetPasswordOpen = () => {
        setResetPasswordOpen(true);
    };
    
    const handleCloseResetForm = () => {
        setResetPasswordOpen(false);
    };


    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
                    <ProfilePic/>
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, paddingTop: 5, paddingX: 2 }}>
                        <Box>
                            <Typography color={'grey'}>First Name</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 20 }}>{userData?.firstName}</Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <Typography color={'grey'}>Last Name</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 20 }}>{userData?.lastName}</Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <Typography color={'grey'}>Address</Typography>
                            <Box sx={{ display: 'flex', columnGap: 1 }}>
                                <Typography sx={{
                                    fontWeight: 500, fontSize: 20,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'normal', // Allow wrapping
                                    wordWrap: 'break-word'
                                }}>{userData?.address}
                                    </Typography> 
                            </Box>
                        </Box>
                        <Divider />
                        <Box>
                            <Typography color={'grey'}>Email</Typography>
                            <Typography noWrap sx={{ fontWeight: 500, fontSize: 20 }}>{userData?.email}</Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <Typography color={'grey'}>Contact Number</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 20 }}>{userData?.contactNumber}</Typography>
                        </Box>
                    </Box>
                
                </Box>
                <Box sx={{display: 'flex', justifyContent:"flex-end"}} >
                    <CustomButton onClick={()=>{handleResetPasswordOpen()}} startIcon={<SettingsIcon />}>
                       Account Settings
                    </CustomButton>
                </Box>
            </Box>
            <AccountSettingsDialog handleClose={handleCloseResetForm} open={resetPasswordOpen} />
                    {/* <Grid container sx={{ p: 3 }} rowGap={2}>
                        <ResetPasswordForm handleClose={handleCloseResetFormAfterSubmit} tempPasswordLabel={"Current Password"}/>
                    </Grid>
                </AccountSettingsDialog> */}
        </>
    );
}

export default AccountDetails;
