// material-ui
import { Badge, Box, Tooltip, useMediaQuery } from "@mui/material";

// project import
import Profile from "./profile/Profile";
import Notification from "./Notification";
import { subjectDashboardDetails } from "../../../../store/slices/subjectSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { base64ToArrayBuffer } from "../../../../components/common/DocumentUpload";
import { bloqcibeApi, useGetSubjectDetailQuery } from "../../../../store/slices/apiSlice";
import file from '../../../../assets/images/Icons/telehealth-iocn.png';
import { openChat } from "../../../../store/slices/chatSlice";
import useChatMessage from "../../../../hooks/useChatMessage";
import { getUserRole } from "../../../../pages/util";
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch()
  const subjectDashboardInfo = useSelector(subjectDashboardDetails);
  const user = useSelector((state) => state.auth.user);
  const userDetails = useSelector((state) => state.userDetails.loggedInUser)
  const subjectLoggedIn = useSelector((state) => state.auth.subjectLoggedIn);
  const [imageSrc, setImageSrc] = useState(null);
  const [downloadSubjectProfilePic] =
        bloqcibeApi.endpoints.downloadSubjectProfilePic.useLazyQuery();
  const { unreadMessagesCount } = useChatMessage();
  const [downloadUserProfilePic] = bloqcibeApi.endpoints.downloadUserProfilePic.useLazyQuery();
  const handleOpenChats = () => {
    dispatch(openChat(true));
  };
  const userRole = getUserRole(user);

  useEffect(() => {
    (async () => {
        if(subjectLoggedIn && subjectDashboardInfo?.subjectDetail?.profilePic) {
            const result = await downloadSubjectProfilePic({
                s3Key: subjectDashboardInfo?.subjectDetail?.profilePic,
            });
            if (result.data) {
                const byteArray = base64ToArrayBuffer(result.data);
                const rr = new Blob([byteArray], { type: "application/octet-stream" });
                const reader = new FileReader();
                reader.readAsDataURL(rr);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setImageSrc(base64data);
                };
            }
        }
        else{
          if(userDetails?.profileS3Key){
            const result = await downloadUserProfilePic({
              s3Key: userDetails?.profileS3Key,
            })
            if(result.data){
              const byteArray = base64ToArrayBuffer(result.data);
                const rr = new Blob([byteArray], { type: "application/octet-stream" });
                const reader = new FileReader();
                reader.readAsDataURL(rr);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setImageSrc(base64data);
              };
            }
          }
        }
    })();
  }, [subjectDashboardInfo, userDetails])

  return (
    <>
      {!matchesXs && (
        <Box
          onClick={handleOpenChats}
          sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, alignItems: 'center', mx: 1, cursor: 'pointer' }}
        >
         {(userRole !== 'sponsor' && userRole !== 'system_admin') && <Tooltip title={'Telehealth'} arrow>
            <Badge
              color="primary"
              badgeContent={unreadMessagesCount}
              sx={{ '& .MuiBadge-badge': unreadMessagesCount && { transform: 'scale(1) translate(50%, -60%)' } }}
            >
              <img src={file} alt="file" style={{ height: 30 }} />
            </Badge>
          </Tooltip>}
        </Box>
      )}
      <Notification />
      <Profile s3Key={imageSrc} />
    </>
  );
};

export default HeaderContent;
