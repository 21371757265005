import React, { useState } from 'react';
import { Grid, Typography, Button, CardContent, Box, Tooltip, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, useTheme} from '@mui/material';
import CustomCard from '../../components/@extended/CustomCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSponsorByIdQuery } from '../../store/slices/apiSlice';
import RightArrow from '../../components/icons/RightArrow';
import OrganizationLogo from './OrganizationLogo';
import SponsorAdmin from './SponsorAdminTable';
import TrailDetailsTable from '../createTrialSystemAdmin/TrailDetailsTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import AssignConfiguration from '../CreateSponsor/AssignConfiguration';

const LabelContainer = ({ label, value }) => {
  return (
    <Grid container py={1} ml={8}>
      <Grid item xs={4} md={5}>
        <Typography variant="subtitle1"  sx={{  fontWeight:"bold"}}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} md={7}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

const SponsorShow = () => {
  const navigate = useNavigate();
  const { sponsorId } = useParams();
  const theme = useTheme()
  const { data: organizationDetails, isLoading } = useGetSponsorByIdQuery(sponsorId);
  const [openAddAdmin, setOpenAddAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (isLoading) {
    return <></>;
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button type="text" onClick={() => navigate('/')} startIcon={<RightArrow leftArrow />} >
          <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
            Back
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CustomCard
          title={
            <Typography variant="h6" fontWeight={600} color={theme.palette.grey[800]}>
              Sponsor Organization Details
            </Typography>
          }
          // action={
          //   <Box>
          //     <Tooltip title={'More'}>
          //       <IconButton variant="outlined" fontSize="small" onClick={handleClick}>
          //         <MoreVertIcon />
          //       </IconButton>
          //     </Tooltip>
          //     <Menu
          //       anchorEl={anchorEl}
          //       open={Boolean(anchorEl)}
          //       onClose={handleCloseMenu}
          //       onClick={handleCloseMenu}
          //       PaperProps={{
          //         elevation: 0,
          //         sx: {
          //           overflow: 'visible',
          //           filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          //           mt: 1.5,
          //           '& .MuiAvatar-root': {
          //             width: 32,
          //             height: 32,
          //             ml: -0.5,
          //             mr: 1,
          //           },
          //           '&::before': {
          //             content: '""',
          //             display: 'block',
          //             position: 'absolute',
          //             top: 0,
          //             right: 14,
          //             width: 10,
          //             height: 10,
          //             bgcolor: 'background.paper',
          //             transform: 'translateY(-50%) rotate(45deg)',
          //             zIndex: 0,
          //           },
          //         },
          //       }}
          //       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          //       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          //     >
          //       <MenuItem
          //         onClick={() => {
          //           handleCloseMenu();
          //           handleOpen();
          //         }}
          //       >
          //         Manage Configuration
          //       </MenuItem>
          //     </Menu>
          //   </Box>
          // }
        >
          <Dialog
            open={open}
            onClose={() => {
              handleClose();
            }}
          >
            <DialogTitle>Manage Configuration</DialogTitle>
            <IconButton
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ pt: 0 }}>
              <AssignConfiguration handleClose={handleClose} sponsorId={sponsorId} />
            </DialogContent>
          </Dialog>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight={160}>
                  <OrganizationLogo organizationDetails={organizationDetails} />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <LabelContainer label={'Organization Name'} value={organizationDetails?.orgnizationName || 'N/A'} />
                <LabelContainer
                  label={'Organization Category'}
                  value={organizationDetails?.orgnizationCategory || 'N/A'}
                />
                <LabelContainer label={'Address'} value={organizationDetails?.address || 'N/A'} />
                <LabelContainer label={'Contact Number'} value={organizationDetails?.contactNumber || 'N/A'} />
                <LabelContainer label={'Fax'} value={organizationDetails?.fax || 'N/A'} />
                <LabelContainer label={'Contact Person'} value={organizationDetails?.contactPerson || 'N/A'} />
                <LabelContainer label={'Contact Email'} value={organizationDetails?.contactEmail || 'N/A'} />
              </Grid>
            </Grid>
          </CardContent>
        </CustomCard>
      </Grid>
      <Grid item sm={12}>
        <SponsorAdmin openAddAdmin={openAddAdmin} />
      </Grid>
      <Grid item sm={12}>
        <TrailDetailsTable />
      </Grid>
    </Grid>
  );
};

export default SponsorShow;
