import { List, styled } from '@mui/material';

const StyledList = styled(List)(({ theme }) => ({
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px', // Adjust the width of the scrollbar here
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[400], // Color of the scrollbar thumb
    borderRadius: '8px', // Rounded corners of the scrollbar thumb
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.grey[500], // Color on hover
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[200], // Background color of the scrollbar track
  },
}));

export default StyledList;