import React from 'react';
import ChatButton from './ChatButton';
import ChatMessageDrawer from './ChatMessageDrawer';

const Chats = () => {
  return (
    <>
      <ChatButton />
      <ChatMessageDrawer />
    </>
  );
};

export default Chats;
