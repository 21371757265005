import React, { useRef, useState } from 'react';

import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import {
  Label,
  MicSelection,
  PreviewVideo,
  SecondaryButton,
  SpeakerSelection,
  useAudioOutputs,
  useLocalAudioInputActivityPreview,
  useLocalVideo,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import CustomButton from '../../../components/@extended/CustomButton';
import { useResponsive } from '../../../hooks/ResponsiveProvider';
import TestSound from '../../../util/TestSound';
import ActivityBar from './ActivityBar';
import { StyledVideoGroup } from './Styled';
import CameraDevices from './cameraDevices/CameraDevices';
import { useNavigate } from 'react-router-dom';

const DeviceSelection = ({ setDeviceSelection }) => {
  const meetingManager = useMeetingManager();
  const activityBarRef = useRef();
  const { toggleVideo } = useLocalVideo(); // Use hook to control local video
  useLocalAudioInputActivityPreview(activityBarRef);
  const navigate = useNavigate()

  const { isSmallScreen } = useResponsive();

  const { selectedDevice } = useAudioOutputs();
  const [selectedOutput, setSelectedOutput] = useState(selectedDevice);

  const handleChange = (deviceId) => {
    setSelectedOutput(deviceId);
  };

  const handleTestSpeaker = () => {
    new TestSound(selectedOutput);
  };

  const handleJoin = async () => {
    // Start the meeting session
    await meetingManager.start();

    // Start local video tile here after joining
    await toggleVideo();

    // Start the local video tile after joining the meeting
    // meetingManager.startLocalVideoTile();
    setDeviceSelection(null);
  };

  const handleCancel = async () => {
    await meetingManager.leave();
    navigate(-1);
  }
  return (
    <>
      <Grid container rowSpacing={isSmallScreen && 2} columnSpacing={!isSmallScreen && 4}>
        <Grid item md={6} sm={12}>
          <Typography variant="h6">Video preview</Typography>
          <PreviewVideo />
        </Grid>
        <Grid item md={6} sm={12} xs={12} sx={{ rowSpacing: 2 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6">Audio</Typography>
            <MicSelection />
            <Box sx={{ marginBottom: '1rem' }}>
              <Label style={{ display: 'block', marginBottom: '.5rem' }}>Microphone activity</Label>
              <ActivityBar ref={activityBarRef} />
            </Box>
            <SpeakerSelection onChange={handleChange} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <SecondaryButton label="Test speakers" onClick={handleTestSpeaker} />
            </Box>
          </Box>
          <Divider orientation="horizontal" flexItem />
          <Box sx={{ pt: 2 }}>
            <StyledVideoGroup>
              <CameraDevices />
            </StyledVideoGroup>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pt: 2 }}>
        <CustomButton variant="contained" color="primary" onClick={handleJoin}>
          Join Meeting
        </CustomButton>
        <CustomButton variant="outlined" color="primary" onClick={handleCancel} sx={{ ml: 2 }}>
          Cancel
        </CustomButton>
      </Box>
    </>
  );
};

export default DeviceSelection;
