import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  useTheme,
  TableRow,
  Tooltip,
  Grid,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import { ConfigurationTableColumns } from './ConfigurationTableColumns';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import TableCellLabel from '../../components/common/TableCellLabel';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CustomTableHead, CustomTableHeadCell, CustomTableRow } from '../../components/@extended/CustomTable';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {bloqcibeApi,  useUploadConfigurationFileMutation } from '../../store/slices/apiSlice';
import { useDispatch } from 'react-redux';
import { openBackdropLoading } from '../../store/slices/applicationSlice';
import { openMessage } from '../../store/slices/showMessageSlice';
import AddIcon from '@mui/icons-material/Add';
import CustomCard from '../../components/@extended/CustomCard';
import CustomButton from '../../components/@extended/CustomButton';


const DynamicConfigurationTable = ({ tableKey, item, deleteConfigOption, updateConfigOption, deleteConfigTable, handleEditTableButton }) => {
  const theme = useTheme();
  const labels = { item };
  const dispatch = useDispatch()
  const options = labels.item.options
  const columns = ConfigurationTableColumns();
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadConfigurationFile, { isLoading, isError }] = useUploadConfigurationFileMutation();
  const [getJsonForm] = bloqcibeApi.endpoints.getJsonForm.useLazyQuery();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (table) => {
    handleEditTableButton(table)
    handleClose();
  };

  const handleDelete = (tablekey) => {
    deleteConfigTable(tablekey)
    handleClose();
  };

  const handleDownload = async (row) => {
    const data = await getJsonForm({
      sponsorId : 'bloqcube',
      documentKey:row.key
    });
    if(data.isError){
      dispatch(openMessage({ message: data?.error?.data?.message ? data?.error?.data?.message : 'unable to download file', messageSeverity: 'error' }));  
    }else{
      dispatch(openMessage({ message:'file downloded succesfully.', messageSeverity: 'success' }));
    }
  }

  return (
    <Box sx={{ mb: 2 }}>
      <CustomCard
        title={item.label}
        action={
          <Box>
            <Tooltip title={'Add new option / Edit Configuration label'} placement='top' arrow>
              <CustomButton variant="outlined" onClick={() => handleEdit(item)} startIcon={<AddIcon />}>
                Add Option
              </CustomButton>
            </Tooltip>
          </Box>
        }
      >
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <TableRow>
                {columns.map((column) => (
                  <CustomTableHeadCell key={column.id} sx={{ ...column.style }}>
                    {column.label}
                  </CustomTableHeadCell>
                ))}
              </TableRow>
            </CustomTableHead>

            <TableBody>
              {options && options.length > 0 ? (
                options.map((row, index) => (
                  <CustomTableRow
                    key={row.key}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                      whiteSpace: 'wrap',
                      wordWrap: 'break-word',
                    }}
                  >
                    <TableCell align="center" sx={{ maxWidth: '200px' }}>
                      <TableCellLabel label={row.label} />
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: '200px' }}>
                      <TableCellLabel style={{ fontStyle: 'italic' }} label={row.key} />
                    </TableCell>
                    {/* <TableCell align="center">
                <TableCellLabel label={'Version'} />
              </TableCell> */}
                    <TableCell align="center" sx={{ maxWidth: '200px' }}>
                      <TableCellLabel label={moment(row.createdAt).format('YYYY-MM-DD')} />
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={'Edit'}>
                        <IconButton
                          fontSize="small"
                          color="gray"
                          onClick={() => {
                            updateConfigOption(row);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={'Download JSON File'}>
                        <IconButton
                          color="gray"
                          fontSize="small"
                          onClick={() => {
                            handleDownload(row);
                          }}
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={'Delete'}>
                        <IconButton
                          color="gray"
                          fontSize="small"
                          onClick={() => {
                            deleteConfigOption(row);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </CustomTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography variant="subtitle1" color="initial">
                      No Data Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomCard>
    </Box>
  );
};

export default DynamicConfigurationTable;

/*   <Box>
            <Tooltip title={'More'}>
              <IconButton variant="outlined" fontSize="small" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                onClick={() => {
                  handleEdit(item);
                }}
              >
                Edit Configuration
              </MenuItem>
                <Divider />
            <MenuItem
                onClick={() => {
                  handleDelete(tableKey);
                }}
              >
                Delete Configuration
              </MenuItem> 
            </Menu>
          </Box>*/

