import CustomButton from '../../components/@extended/CustomButton';
import CreateConfiguration from '../Configuration/CreateConfiguration';
import DynamicConfigurationTable from '../Configuration/DynamicConfigurationTable';
import React, { useState } from 'react';
import { Box, Grid, Drawer, Typography, Stack } from '@mui/material';
import { useDeleteConfigurationOptionMutation, useDeleteConfigurationTableMutation, useGetAllConfigurationsQuery} from '../../store/slices/apiSlice';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import EditConfigurationOption from '../Configuration/EditConfigurationOption';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@emotion/react';
const ConfigurationDrawer = ({anchor, isOpen, onToggle, updateOption, setupdateOption, configTable ,isEditing }) => {
  let Options;
  if(configTable){
      Options = configTable.options.map(option => ({
      id:option.id,
      optionKey: option.key,
      option: option.label,
      version:option.version
  }));
  }

  const initialValue = configTable ? {
    id: configTable.id,
    labelKey: configTable.key,
    label: configTable.label,
    options: Options
  } : {
    id:'',
    labelKey: '',
    label: '',
    options: [{ id:'', optionKey: '', option: '', version:'' }]
  };
  return(
  <Drawer sx={{
    flexShrink: 0,
  }} anchor={anchor} open={isOpen} onClose={onToggle}>
    <Box sx={{ width: 400, pt: 8 }}>
      <Grid container>
        <Grid item sm={12}>
      {updateOption ? (
            <EditConfigurationOption toggleDrawer={onToggle} anchor={anchor} updateOption={updateOption} setupdateOption={setupdateOption} />
          ) : (
            <CreateConfiguration toggleDrawer={onToggle} anchor={anchor} initialValue={initialValue} isEditing={isEditing} />
          )}
        </Grid>

      </Grid>
    </Box>
  </Drawer>
  )
};

const ManageConfiguration = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme(); 
  const {data:configurationList} = useGetAllConfigurationsQuery();
  const Labels = configurationList || [];
  const handleOpenDrawer = (anchor) => () => setIsOpen(true); 
  const handleCloseDrawer = () => {
    setIsOpen(false)
    setupdateOption(null);
    setConfigTable(null)
    setIsEditing(false)
  };

const [deleteOption] = useDeleteConfigurationOptionMutation();
const [confirmDialog, setConfirmDialog] = useState({
  open: false,
  message: (
    <>
      <span>Are you sure you want to delete this Option?</span>
      <br />
      <span>
        This action cannot be undone. Please confirm whether you want to
        proceed with the deletion.
      </span>
    </>
  ),
  buttonLabel: "Delete",
});

const [deletedOptionId,setDeletedOptionId]=useState();

    const openMessageNotification = (message) => {
        dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
    };

    const deleteConfigOption = async (option) => {
      setDeletedOptionId(option.id)
      setConfirmDialog({
          ...confirmDialog,
          open: true,
          message: (
            <>
              <span>
                Are you sure you want to delete this Option?
              </span>
            </>
          ),
      }); 
  };

  const handleDeleteOption = async () => {
    if (deletedOptionId) {
    const result = await deleteOption(deletedOptionId);
    if (!result?.error) {
        openMessageNotification({
            message: "Option deleted successfully",
            type: "success",
        });
      } else {
        openMessageNotification({
          message: result?.error?.data?.message,
          type: "error",
        });
    }
    setConfirmDialog({
        ...confirmDialog,
        open: false,
    });
    }
}

const handleConfirmDialogClose = () => {
  setConfirmDialog({
    ...confirmDialog,
    open: false,
  });
};

 const [updateOption,setupdateOption]=useState();
  const updateConfigOption = (row) => {
    setupdateOption(row);
    setIsOpen(true);
  }

  const [deleteTable] = useDeleteConfigurationTableMutation();
  const [tableId, setTableId] = useState();
  const [confirmDialog1, setConfirmDialog1] = useState({
    open: false,
    message: (
      <>
        <span>Are you sure you want to delete this Table?</span>
        <br />
        <span>
          This action cannot be undone. Please confirm whether you want to
          proceed with the deletion.
        </span>
      </>
    ),
    buttonLabel: "Delete",
  });

  const openMessageNotification1 = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
  };

const deleteConfigTable = async (tablekey) => {
  setTableId(tablekey)
  setConfirmDialog1({
      ...confirmDialog1,
      open: true,
      message: (
        <>
          <span>
            Are you sure you want to delete this Table?
          </span>
        </>
      ),
  }); 
};

const handleDeleteTable = async () => {
  if (tableId) {
  const result = await deleteTable(tableId);
  if (!result?.error) {
      openMessageNotification1({
          message: "Configuration Table deleted successfully",
          type: "success",
      });
    } else {
      openMessageNotification1({
        message: result?.error?.data?.message,
        type: "error",
      });
  }
  setConfirmDialog1({
      ...confirmDialog1,
      open: false,
  });
  }
}

const handleConfirmDialogClose1 = () => {
  setConfirmDialog1({
    ...confirmDialog1,
    open: false,
  });
};

// Update Table
const [configTable, setConfigTable] = useState();
const [isEditing, setIsEditing] = useState(false);

const handleEditTableButton = (table) =>{
  setConfigTable(table);
  setIsEditing(true);
  setIsOpen(true);
}
  return (
    <>
    <Grid container>
      {/* <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomButton variant="contained" onClick={handleOpenDrawer('right')} startIcon={<AddIcon />}>
              Add New Configuration
          </CustomButton>
      </Grid> */}
      <Grid item sm={12}>
          {Labels.length > 0 && Labels.map((item, index) => (  
                <DynamicConfigurationTable tableKey={item.id} item={item} handleOpenDrawer={handleOpenDrawer} deleteConfigOption={deleteConfigOption} updateConfigOption={updateConfigOption} deleteConfigTable={deleteConfigTable} handleEditTableButton={handleEditTableButton}/>
          ))}
          {
            Labels.length === 0 &&
            <Stack alignItems='center' justifyContent='center' sx={{p:5, mt:3}}>
              <Typography variant='h5' sx={{color:theme.palette.grey[700]}}>Configuration data not available.</Typography>
              <Typography variant='body2' sx={{color:theme.palette.grey[500]}}> Note : you have to add new configuration</Typography>
            </Stack>
          }
      </Grid>
    </Grid>
    {isOpen && <ConfigurationDrawer anchor="right" isOpen={isOpen} onToggle={handleCloseDrawer} updateOption={updateOption} setupdateOption={setupdateOption} configTable={configTable} isEditing={isEditing}/>}
    <ConfirmationDialog
            open={confirmDialog.open}
            buttonLabel={confirmDialog.buttonLabel}
            message={confirmDialog.message}
            handleClose={handleConfirmDialogClose}
            handleConfirm={handleDeleteOption}
        />
        <ConfirmationDialog
            open={confirmDialog1.open}
            buttonLabel={confirmDialog1.buttonLabel}
            message={confirmDialog1.message}
            handleClose={handleConfirmDialogClose1}
            handleConfirm={handleDeleteTable}
        />
    </>
  );
};

export default ManageConfiguration;