import React, { useState } from 'react';
import {
  ControlBar,
  AudioInputControl,
  VideoInputControl,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  useUserActivityState,
  Dots,
  Phone,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { Box, Menu, MenuItem, ListItemIcon, Typography } from '@mui/material';
import { PersonAdd, ExitToApp, ComputerOutlined } from '@mui/icons-material';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '../../../hooks/ResponsiveProvider';

const MeetingControls = ({ setAttendeesDrawerOpen }) => {
  //   const { toggleNavbar, closeRoster, showRoster } = useNavigation();
  const { isUserActive } = useUserActivityState();
  const { isSmallScreen } = useResponsive();
  const meetingManager = useMeetingManager();
  const navigate = useNavigate();

  // State for the menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Handle menu open/close
  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInviteMembers = () => {
    console.log('Invite members clicked');
    // Add logic to invite members
    handleClose();
  };

  const handleLeave = async () => {
    await meetingManager.leave();
    navigate('/');
    console.log('LEAVING MEETING');
  };

  const handleShareScreen = () => {
    handleClose();
  };

  const handleOpenParticipantsDrawer = () => {
    handleClose();
    setAttendeesDrawerOpen();
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '@media (max-width: 768px)': {
          opacity: 1,
        },
        '.controls-menu': {
          width: '100%',
          position: 'static',
        },
        opacity: `${isUserActive || open ? '1' : '0'}`,
        transition: 'opacity 250ms ease',
      }}
    >
      <ControlBar className="controls-menu" layout="undocked-horizontal" showLabels responsive={true}>
        <ControlBarButton className="mobile-toggle" icon={<Dots />} onClick={handleToggle} label="Menu" />
        <AudioInputControl />
        <VideoInputControl />
        {!isSmallScreen && <ContentShareControl />}
        {!isSmallScreen && <AudioOutputControl />}
        <ControlBarButton icon={<Phone />} onClick={handleLeave} label="Leave" />
      </ControlBar>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleInviteMembers}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Invite Participants</Typography>
        </MenuItem>
        {isSmallScreen && (
          <MenuItem onClick={handleShareScreen}>
            <ListItemIcon>
              <ComputerOutlined fontSize="small" />
              {/* <ContentShareControl fontSize="small" /> */}
            </ListItemIcon>
            <Typography variant="inherit">Share Screen</Typography>
          </MenuItem>
        )}
        {isSmallScreen && (
          <MenuItem onClick={handleOpenParticipantsDrawer}>
            <ListItemIcon>
              <PeopleOutlineIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">View Participants</Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default MeetingControls;
