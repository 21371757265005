import { Box, Typography, Grid, IconButton, Tooltip } from '@mui/material';
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  ScreenShare,
  StopScreenShare,
  SignalCellularAlt,
  SignalCellularConnectedNoInternet0Bar,
} from '@mui/icons-material';
import React from 'react';
import { useAttendeeStatus } from 'amazon-chime-sdk-component-library-react';
import { useResponsive } from '../../../hooks/ResponsiveProvider';

const AttendeeStatus = ({ attendeeId }) => {
  const { muted, videoEnabled, sharingContent, signalStrength } = useAttendeeStatus(attendeeId);
  const { isSmallScreen } = useResponsive();
  return (
    <Box key={attendeeId} paddingY={2} borderBottom="1px solid #ccc" display="flex" alignItems="center">
      <Grid container alignItems="center" spacing={!isSmallScreen && 1} columnSpacing={isSmallScreen && 2}>
        {/* Signal Strength Icon */}
        <Grid item md={1} xs={2}>
          <Tooltip title={`Signal Strength: ${signalStrength || 'Unknown'}`}>
            {signalStrength ? (
              <SignalCellularAlt fontSize="small" />
            ) : (
              <SignalCellularConnectedNoInternet0Bar fontSize="small" />
            )}
          </Tooltip>
        </Grid>

        {/* Attendee Info */}
        <Grid item md={6.5} xs={10}>
          <Typography variant="body1">{`Attendee (${attendeeId})`}</Typography>
        </Grid>

        {/* Mute/Unmute Icon */}
        <Grid item md={1.5} xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title={muted ? 'Muted' : 'Unmuted'}>
            <IconButton>{muted ? <MicOff color="error" /> : <Mic color="primary" />}</IconButton>
          </Tooltip>
        </Grid>

        {/* Video Enabled/Disabled Icon */}
        <Grid item md={1.5} xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title={videoEnabled ? 'Video Enabled' : 'Video Disabled'}>
            <IconButton>{videoEnabled ? <Videocam color="primary" /> : <VideocamOff color="disabled" />}</IconButton>
          </Tooltip>
        </Grid>

        {/* Screen Sharing Icon */}
        <Grid item md={1.5} xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title={sharingContent ? 'Sharing Content' : 'Not Sharing Content'}>
            <IconButton>
              {sharingContent ? <ScreenShare color="primary" /> : <StopScreenShare color="disabled" />}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttendeeStatus;
