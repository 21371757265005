import { Badge, Fab, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import { useDispatch, useSelector } from 'react-redux';
import { openChat } from '../../../store/slices/chatSlice';
import { keyframes } from '@mui/system';
import { filter } from 'lodash';
import useChatMessage from '../../../hooks/useChatMessage';

const ChatButton = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleOpenChats = () => {
    dispatch(openChat(true));
  };

  const { unreadMessagesCount } = useChatMessage();

  const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-2px);
    }
  `;

  return (
    <Fab onClick={handleOpenChats} aria-label="add" sx={{ position: 'fixed', right: 20, bottom: 40 , backgroundColor:'#001833' , "&: hover":{backgroundColor:'#001833'},  display: { xs: 'block', sm: 'block', md: 'none' }}}>
      <Badge color='primary' badgeContent={unreadMessagesCount} sx={{'& .MuiBadge-badge' : unreadMessagesCount && {transform: 'scale(1) translate(80%, -95%)'}}}>
        <ChatIcon sx={{ color: theme.palette.common.white  , animation: unreadMessagesCount ? `${bounce} 2s infinite` : 'none'}} />
      </Badge>
    </Fab>
  );
};

export default ChatButton;
