import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Dialog, Grid, Button } from "@mui/material";
import CustomButton from "../../components/@extended/CustomButton";
import { useResponsive } from "../../hooks/ResponsiveProvider";
import ResetPasswordForm from "./resetPasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { setPasswordReset } from "../../store/slices/applicationSlice";


const ResetPasswordDialog = ({ userData }) => {
  const theme = useTheme();
  const { isSmallScreen } = useResponsive();
  const dispatch = useDispatch();
  const { openDialog, formLabel, isCancellable, isTempPassword } = useSelector((state) => state.application.passwordReset);

  //open reset password form
  const [openResetForm, setOpenResetForm] = useState(false);

  const handleClose = (isSubmitted) => {
    if (isCancellable || isSubmitted) {
        dispatch(
          setPasswordReset({
            openDialog: false,
            isTempPassword: false,
          })
        );
        setOpenResetForm(false);
    }
  };

  const handleOpenResetForm = () => {
    setOpenResetForm(true);
  };

  return (
    <Dialog onClose={() => handleClose()} open={openDialog}>
      <Grid container sx={{ p: 3 }} rowGap={2}>
        {openResetForm ? (
          <ResetPasswordForm
            handleClose={(value) => handleClose(value)}
            tempPasswordLabel={isTempPassword ? 'Temporary Password' : 'Current Password'}
            isCancellable={isCancellable}
          />
        ) : (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4, paddingTop: isSmallScreen ? 8 : 4 }}>
              <Typography variant="subtitle1" color="initial">
                {formLabel}
                {/* Your temporary password will expire in 24 hours. Please reset your password. */}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {isCancellable && (
                <Button
                  sx={{ width: 150, color: theme.palette.primary.light }}
                  // size="large"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              )}
              <CustomButton
                variant="text"
                onClick={() => {
                  handleOpenResetForm();
                }}
                size="small"
              >
                Reset password
              </CustomButton>
            </Box>
          </Box>
        )}
      </Grid>
    </Dialog>
  );
};

export default ResetPasswordDialog;
