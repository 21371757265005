import { Box, Divider, Drawer, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setQuizDrawer } from '../../store/slices/applicationSlice';
import CustomButton from '../@extended/CustomButton';
import { useResponsive } from '../../hooks/ResponsiveProvider';

const QuizDrawer = () => {
  const dispatch = useDispatch();
  const quizDrawer = useSelector((state) => state.application.quizDrawer);
  const { isSmallScreen } = useResponsive();
  const handleClose = () => {
    dispatch(setQuizDrawer(false));
  };
  return (
    <>
      <Drawer
        anchor="right"
        open={quizDrawer}
        onClose={handleClose}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isSmallScreen ? '100%' : 600, // Set the width of the Drawer paper
            height: '100%',
            paddingTop: { sm: 0, md: 0, lg: 8 },
            backgroundColor: '#F5F5F5',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <Box sx={{ padding: 3 }}>
            <Typography sx={{ fontSize: 17, fontWeight: 600, color: '#000' }}>Complete Quiz</Typography>
          </Box>
          <Divider />
          <Box sx={{ width: '100%', padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    1. Do you understand the purpose of the clinical trial?
                  </Typography>
                  <RadioGroup row name="understandPurpose">
                    <FormControlLabel value={'yes'} control={<Radio />} label={'Yes'} />
                    <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    2. Do you understand how long the trial will last and what your commitments will be?
                  </Typography>
                  <RadioGroup row name="understandPurpose">
                    <FormControlLabel value={'yes'} control={<Radio />} label={'Yes'} />
                    <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    3. Do you understand the potential risks and benefits of participating in the trial?
                  </Typography>
                  <RadioGroup row name="understandPurpose">
                    <FormControlLabel value={'yes'} control={<Radio />} label={'Yes'} />
                    <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    4. Do you understand how your personal information will be kept confidential?
                  </Typography>
                  <RadioGroup row name="understandPurpose">
                    <FormControlLabel value={'yes'} control={<Radio />} label={'Yes'} />
                    <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    5. Do you know whom to contact if you have questions or concerns about the consent form or the
                    trial?
                  </Typography>
                  <RadioGroup row name="understandPurpose">
                    <FormControlLabel value={'yes'} control={<Radio />} label={'Yes'} />
                    <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    6. In your own words, can you describe why we are doing this project?
                  </Typography>
                  <CommonTextField multiline={true} placeholder={'Describe'} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    7. Can you describe what will happen if you agree to participate?
                  </Typography>
                  <CommonTextField multiline={true} placeholder={'Describe'} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    8. Can you describe what will happen if you decline?
                  </Typography>
                  <CommonTextField multiline={true} placeholder={'Describe'} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    9. In your own words, what does "Voluntary"/"Withdraw"/"Risk"/"Benefit" mean?
                  </Typography>
                  <CommonTextField multiline={true} placeholder={'Describe'} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    10. What do you expect to gain or lose by participating?
                  </Typography>
                  <CommonTextField multiline={true} placeholder={'Describe'} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography sx={{ color: '#5b5b5b', fontWeight: 'medium' }} variant="subtitle1" gutterBottom>
                    11. What are your alternative choices if you do not participate?
                  </Typography>
                  <CommonTextField multiline={true} placeholder={'Describe'} />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 'auto', padding: 3, pt: 0, display: 'flex', justifyContent: 'center' }}>
            <CustomButton variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
              Close
            </CustomButton>
            <CustomButton onClick={handleClose}>Save</CustomButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default QuizDrawer;

const CommonTextField = ({ placeholder, multiline }) => {
  return (
    <Box sx={{width:'100%'}}>
      <TextField
        multiline={multiline}
        placeholder={placeholder}
        fullWidth
        sx={{
          '& input[type=number]': {
            '-moz-appearance': 'textfield',
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
        }}
      />
    </Box>
  );
};
