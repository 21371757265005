import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import { TranscribeStreamingClient, StartStreamTranscriptionCommand } from '@aws-sdk/client-transcribe-streaming';
import MicrophoneStream from 'microphone-stream';
import { Buffer } from 'buffer';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../@extended/CustomButton';
import Lottie from 'lottie-react';
import loadingAnimation from "../../../assets/animations/loadingAnimation.json"
import { useDispatch } from 'react-redux';
import { openMessage } from '../../../store/slices/showMessageSlice';
import { useGetSQSCredsQuery } from '../../../store/slices/apiSlice';

// const region = process.env.REACT_APP_TRANSCRIBE_AWS_REGION;
const region = "ap-south-1"

const ChatMessageBox = ({ chat, setChat, isLoading, sendChat }) => {
  const [isRecording, setIsRecording] = useState(false);
  const transcribeClientRef = useRef(null);
  const microphoneStreamRef = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data: sqsCreds, isLoading: sqsLoading, refetch } = useGetSQSCredsQuery();

  const SAMPLE_RATE = 44100;

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  const startRecording = async () => {
    try {
      // Refetch SQS credentials
      const { data: refreshedCreds } = await refetch();
      if (!refreshedCreds?.accessKeyId || !refreshedCreds?.secretAccessKey) {
        console.error('Failed to refresh SQS credentials!');
        dispatch(
          openMessage({
            messageSeverity: 'error',
            message: 'Failed to refresh SQS credentials!',
          })
        );
        return;
      }

      // Set up Transcribe client with refreshed credentials
      const credentials = {
        accessKeyId: refreshedCreds.accessKeyId,
        secretAccessKey: refreshedCreds.secretAccessKey,
        sessionToken: refreshedCreds.sessionToken,
      };

      transcribeClientRef.current = new TranscribeStreamingClient({
        region,
        credentials,
      });

      // Start microphone stream
      setIsRecording(true);
      const microphoneStream = new MicrophoneStream();
      const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });
      microphoneStream.setStream(stream);
      microphoneStreamRef.current = microphoneStream;

      const audioStream = async function* () {
        for await (const chunk of microphoneStream) {
          const pcmChunk = encodePCMChunk(chunk);
          yield {
            AudioEvent: {
              AudioChunk: pcmChunk,
            },
          };
        }
      };

      // Start transcription
      const command = new StartStreamTranscriptionCommand({
        LanguageCode: 'en-US',
        MediaEncoding: 'pcm',
        MediaSampleRateHertz: SAMPLE_RATE,
        AudioStream: audioStream(),
      });

      const response = await transcribeClientRef.current.send(command);
      for await (const event of response.TranscriptResultStream) {
        const results = event.TranscriptEvent.Transcript.Results;
        if (results.length && !results[0].IsPartial) {
          const transcript = results[0].Alternatives[0].Transcript;
          setChat((prev) => prev + ' ' + transcript);
        }
      }
    } catch (err) {
      console.error('Error during transcription:', err);
      dispatch(
        openMessage({
          messageSeverity: 'error',
          message: 'An error occurred during transcription!',
        })
      );
      stopRecording();
    }
  };

  const encodePCMChunk = (chunk) => {
    const input = MicrophoneStream.toRaw(chunk);
    const buffer = new ArrayBuffer(input.length * 2);
    const view = new DataView(buffer);
    let offset = 0;
    for (let i = 0; i < input.length; i++, offset += 2) {
      let s = Math.max(-1, Math.min(1, input[i]));
      view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return Buffer.from(buffer);
  };

  const stopRecording = () => {
    if (microphoneStreamRef.current && microphoneStreamRef.current.stream.getTracks()[0].readyState === "live") {
      microphoneStreamRef.current.stop();
      microphoneStreamRef.current.destroy();
      microphoneStreamRef.current = null;
    }
    if( transcribeClientRef.current){
      transcribeClientRef.current = null
    }
    setIsRecording(false);
  };

   const handleSendChat = () => {
     stopRecording();
     sendChat();
   };

  return (
    <Box
      sx={{
        flexShrink: 0,
        padding: '1rem',
        display: 'flex',
        gap: '1rem',
        alignItems: 'flex-end',
        borderTop: `3px solid ${theme.palette.primary.main}`,
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', columnGap: 2, alignItems: 'center' }}>
        <TextField
          value={chat}
          onChange={(e) => setChat(e.target.value)}
          size="small"
          placeholder="Type your message"
          fullWidth
          multiline
          maxRows={4}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={isRecording ? stopRecording : startRecording}
                color={isRecording ? 'error' : 'primary'}
                aria-label="mic"
              >
                {isRecording ? (
                  <Box sx={{ height: 23, width: 23, display: 'flex', justifyContent: 'center' }}>
                    <Lottie animationData={loadingAnimation} loop={true} height={5} width={5} />
                  </Box>
                ) : sqsLoading ? (
                  <Box sx={{ height: 23, width: 23, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <MicIcon />
                )}
              </IconButton>
            ),
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              sendChat();
            }
          }}
        />
        <CustomButton variant="contained" onClick={handleSendChat} disabled={isLoading} sx={{ padding: '0.5rem 1rem' }}>
          <SendIcon />
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ChatMessageBox;
