import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash'
const slice = createSlice({
  name: "notifications",
  initialState: { messages: [], count: 0 },
  reducers: {
    onSocketConnect: (state, action) => {
      const filteredMessages = _.filter(action.payload.rows, (msg) => {
        const messagePayload = JSON.parse(msg.content);
        if (messagePayload.action !== 'CHAT_MESSAGE') {
          return true;
        }
      });
      const orderedMessages = _.orderBy(filteredMessages,'createdAt', 'desc');
      state.messages = orderedMessages;
      state.count = action.payload.count;
    },
    onNewNotification: (state, action) => {
      const newMessage = action.payload;
      state.messages = _.orderBy([...state.messages, newMessage], "createdAt", "desc");
      state.count += 1;
    },
    onMessageReadConfirmation:(state, action) => {
      let updatedMessages ;
      if (action?.payload?.id) {
        updatedMessages = state.messages.map((message) => {
          if (message.id === action.payload.id) {
            return action.payload;
          } else {
            return message;
          }
        });
        state.messages = updatedMessages;
      } else {
        const filteredMessages = _.filter(action.payload, (msg) => {
          const messagePayload = JSON.parse(msg.content);
          if (messagePayload.action !== 'CHAT_MESSAGE') {
            return true;
          }
        });
        const orderedMessages = _.orderBy(filteredMessages, 'createdAt', 'desc');
        state.messages = orderedMessages;
      }
      
    }
  },
});

export default slice.reducer;

export const { onSocketConnect, onNewNotification, onMessageReadConfirmation } =
  slice.actions;
