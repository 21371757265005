import { Typography } from '@mui/material';
import {
    CameraSelection,
    Label,
    PreviewVideo,
    QualitySelection
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import { StyledInputGroup } from '../Styled';

const CameraDevices = () => {
  return (
    <div>
      <Typography variant="h6">Video</Typography>
      <StyledInputGroup>
        <CameraSelection />
      </StyledInputGroup>
      <StyledInputGroup>
        <QualitySelection />
      </StyledInputGroup>
    </div>
  );
};

export default CameraDevices;
