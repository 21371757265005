import { createSlice } from '@reduxjs/toolkit';
import { bloqcibeApi } from './apiSlice';

const chatSlice = createSlice({
  name: 'chats',
  initialState: {
    contacts:[],
    openChat: false,
    chatThreads: [],
    selectedUser: null,
  },
  reducers: {
    openChat(state, action) {
      state.openChat = action.payload;
    },
    updateChatThread(state, action) {
      const { selectedUserId, message } = action.payload;
      const threadIndex = state.chatThreads.findIndex((thread) => thread?.userDetails?.id === selectedUserId);
      if (threadIndex !== -1) {
        state.chatThreads[threadIndex].messages.push(message);
      } else {
        bloqcibeApi.util.invalidateTags(['allMessages'])
      }
      state.selectedUser?.messages.push(message);
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    onRealtimeMessage(state, action) {
      const { sentBy, thread } = action.payload;
      const threadIndex = state.chatThreads.findIndex((thread) => thread?.userDetails?.id === sentBy);
      if (threadIndex !== -1) {
        state.chatThreads[threadIndex] = thread;
      } else {
        state.chatThreads.push(thread);
      }

    if (state.selectedUser !== null && state.selectedUser?.userDetails?.id === sentBy) {
      state.selectedUser.messages = thread?.messages;
    }
      
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(bloqcibeApi.endpoints.getMessageThreads.matchFulfilled, (state, { payload }) => {
      state.chatThreads = payload;
    });
    builder.addMatcher(bloqcibeApi.endpoints.getUserContacts.matchFulfilled,(state, { payload }) => {
      state.contacts = payload;
    });
  },
});

export default chatSlice.reducer;

export const { openChat, updateChatThread, setSelectedUser, onRealtimeMessage } = chatSlice.actions;
